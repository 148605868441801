import { FC, useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { AccessoriesAvailabilityInterface } from 'ks-common';

import { FormContainer, Form } from 'app/hooks/useGlobalForm';
import FormLabel from 'app/components/FormLabel';
import NameTag from 'app/screens/Accessories/Components/NameTag';
import Accessories from 'app/screens/Accessories/Components/Accessories';
import useGetAccessoriesAvailability from 'app/hooks/useGetAccessoriesAvailability';
import CommentsAndNotes from './CommentsAndNotes';
import ReadonlyAccessories from './Accessories';
import Insurance from './Insurance';
import Location from './Location';
import mergeWith from 'lodash/mergeWith';
import cloneDeep from 'lodash/cloneDeep';
import mergeArrayCustomizer from 'utils/mergeArrayCustomizer';

export interface FormErrors {
  nameTag: string;
}
interface Props {
  product: Partial<Form>;
  index: number;
  readonly?: boolean;
  isEdit?: boolean;
}

const ProductDisplay: FC<Props> = ({ product, index, readonly, isEdit }) => {
  const { products, setProducts } = FormContainer.useContainer();
  const [formErrors, setFormErrors] = useState<Partial<FormErrors>>();

  const checkFormErrors = () => {
    setFormErrors(undefined);

    const errors: Partial<FormErrors> = {};
    if (!product.accessories?.nameTag) {
      errors.nameTag = 'Please enter your family name';
    }

    if (Object.keys(errors).length) {
      setFormErrors(errors);
      return errors;
    }

    return undefined;
  };

  const nameTag = product.accessories?.nameTag;

  useEffect(() => {
    checkFormErrors();
  }, [nameTag]);

  const {
    data: accessoriesAvailability,
    error: accessoriesError,
    execute: accessoriesExecute,
  } = useGetAccessoriesAvailability({
    startDate: product?.deliveryDate,
    endDate: product?.returnDate,
    productId: product?.product?.ProductID,
  });

  useEffect(() => {
    if (
      !!product?.deliveryDate &&
      !!product?.returnDate &&
      !!product?.product?.ProductID
    ) {
      accessoriesExecute();
    }
  }, [product?.deliveryDate, product?.returnDate, product?.product?.ProductID]);

  const updateTemporaryForm = (value: Partial<Form>) => {
    const newProducts = cloneDeep(products);
    const updatedProduct = mergeWith(
      newProducts[index],
      value,
      mergeArrayCustomizer,
    );
    newProducts[index] = updatedProduct;
    setProducts(cloneDeep(newProducts));
  };

  return (
    <Box py={0} display="flex" flexDirection="column">
      <Location
        product={product}
        index={index}
        readonly={readonly}
        isEdit={isEdit}
      />

      <Divider sx={{ mt: 2, backgroundColor: 'primary.darker' }} />

      <Box px={1.5} mt={2}>
        <FormLabel>Selected Accessories:</FormLabel>

        {readonly ? (
          <ReadonlyAccessories product={product} index={index} />
        ) : (
          <Accessories
            product={product}
            accessoriesAvailability={
              accessoriesAvailability as AccessoriesAvailabilityInterface[]
            }
            error={accessoriesError}
            form={product}
            updateForm={updateTemporaryForm}
          />
        )}

        <Insurance product={product} index={index} readonly={readonly} />

        <NameTag
          product={product}
          index={index}
          error={formErrors?.nameTag}
          variant="standard"
          isReview
          readonly={readonly}
        />

        <CommentsAndNotes product={product} index={index} readonly={readonly} />
      </Box>
    </Box>
  );
};

export default ProductDisplay;
