import emailValidator from 'utils/validators/emailValidator';
import { CustomerForm, AgentForm } from 'app/hooks/useOrderCheckout';
import { customerFormFields, agentFormFields } from './formfields';
import { isMobile } from 'utils/isMobile';

export type FormErrors<T> = Partial<Record<keyof T, string>>;

interface CustomerValidator {
  customerForm: Partial<CustomerForm>;
  recaptchaToken?: string;
  setRecaptchaError: React.Dispatch<React.SetStateAction<string>>;
  setCustomerErrors: React.Dispatch<
    React.SetStateAction<Partial<Record<keyof CustomerForm, string>>>
  >;
}

const SHOW_RECAPTCHA = process.env.REACT_APP_SHOW_RECAPTCHA;

export const customerValidate = (params: CustomerValidator) => {
  const { customerForm, recaptchaToken, setCustomerErrors, setRecaptchaError } =
    params;

  const validationErrors: FormErrors<CustomerForm> = {};

  if (
    customerForm?.primaryCellPhone !== customerForm?.confirmPrimaryCellPhone
  ) {
    validationErrors.confirmPrimaryCellPhone =
      'Cell phone confirmation should match the Primary Cell phone';
  }

  if (
    customerForm?.emailAddress &&
    !emailValidator(customerForm.emailAddress)
  ) {
    validationErrors.emailAddress = 'Must be a valid e-mail';
  }

  if (
    customerForm?.secondaryEmail &&
    !emailValidator(customerForm?.secondaryEmail)
  ) {
    validationErrors.secondaryEmail = 'Must be a valid e-mail';
  }

  if (customerForm?.emailAddress !== customerForm?.confirmEmail) {
    validationErrors.confirmEmail =
      'Email confirmation should match the Email Address';
  }

  if (!customerForm?.rentalAgreement) {
    validationErrors.rentalAgreement =
      'You must agree to Rental Agreement in order to continue';
  }

  setRecaptchaError('');
  if (!!SHOW_RECAPTCHA && !recaptchaToken && !isMobile) {
    setRecaptchaError('Please verify that you are not a robot.');
  }

  // Required validation for all fields
  customerFormFields().forEach(field => {
    if (field.required && !customerForm[field.name]) {
      validationErrors[field.name] = `${field.label} is required`;
    }
  });

  setCustomerErrors(validationErrors);

  return Object.keys(validationErrors).length === 0;
};

interface AgentValidator {
  isAgent: boolean;
  agentForm: Partial<AgentForm>;
  setAgentErrors: React.Dispatch<
    React.SetStateAction<Partial<Record<keyof AgentForm, string>>>
  >;
}

export const agentValidate = (params: AgentValidator) => {
  const { isAgent, agentForm, setAgentErrors } = params;

  if (!isAgent) return true;

  const validationErrors: FormErrors<AgentForm> = {};

  if (agentForm?.agentEmail && !emailValidator(agentForm.agentEmail)) {
    validationErrors.agentEmail = 'Must be a valid e-mail';
  }

  if (
    agentForm?.payPalCommissionAccount &&
    !emailValidator(agentForm.payPalCommissionAccount)
  ) {
    validationErrors.payPalCommissionAccount = 'Must be a valid e-mail';
  }

  if (
    agentForm?.agentPhoneNumber &&
    agentForm.agentPhoneNumber?.length !== 10
  ) {
    validationErrors.agentPhoneNumber = 'Invalid phone number format';
  }

  // Required validation for all fields
  agentFormFields.forEach(field => {
    if (field.required && !agentForm[field.name]) {
      validationErrors[field.name] = `${field.label} is required`;
    }
  });

  setAgentErrors(validationErrors);

  return Object.keys(validationErrors).length === 0;
};
