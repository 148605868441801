import { FC, useEffect, Fragment } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';
import { FormContainer } from 'app/hooks/useGlobalForm';
import { Helmet } from 'react-helmet';
import ThankYouMobile from './ThankYouMobile';
import ThankYouDesktop from './ThankYouDesktop';
import { isMobile } from 'utils/isMobile';
import { gaCartFormatter } from 'utils/GAnalytics/formatters';
import { OrderCheckoutOutputInterface } from 'ks-common';
import cloneDeep from 'lodash/cloneDeep';
import priceMask from 'utils/priceMask';

const ENV = process.env.REACT_APP_ENV;

const ThankYou: FC = () => {
  const { products, setThankYouProducts, clearForm, isEdit } =
    FormContainer.useContainer();
  const location = useLocation();
  const state = location?.state as OrderCheckoutOutputInterface;
  const orderId = state?.OrderId;
  const orderQuote = state?.OrderQuoteCalculated;

  useEffect(() => {
    setThankYouProducts(cloneDeep(products));
    if (!isEdit) {
      const gaData = gaCartFormatter(state.OrderQuoteCalculated);
      const purchaseData = {
        transaction_id: orderId,
        tax: priceMask(orderQuote.TaxValue * 100, true),
        ...gaData,
      };

      !isEdit && ReactGA.event('purchase', purchaseData);

      clearForm();
    }
  }, []);

  return (
    <Fragment>
      <Helmet>
        {ENV === 'PRODUCTION' && (
          <script>{`<!-- Google Tag Manager -->
(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-T9PWBRCX');
<!-- End Google Tag Manager —>`}</script>
        )}
      </Helmet>
      {ENV === 'PRODUCTION' && (
        <iframe
          src="https://www.googletagmanager.com/ns.html?id=GTM-T9PWBRCX"
          height="0"
          width="0"
          style={{ display: 'none', visibility: 'hidden' }}
        ></iframe>
      )}
      {isMobile ? <ThankYouMobile /> : <ThankYouDesktop />}
    </Fragment>
  );
};

export default ThankYou;
