import { FC, ReactNode } from 'react';
import { Box, Divider } from '@mui/material';
import { Text } from '@concepta/react-material-ui';
import { DateObject } from 'react-multi-date-picker';
import isToday from 'date-fns/isToday';

import { Form, LocationType, FormContainer } from 'app/hooks/useGlobalForm';
import ExpandableStrollerCard, {
  ExpandableStrollerCardType,
} from 'app/components/ExpandableStrollerCard';
import FormLabel from 'app/components/FormLabel';
import { isMobile } from 'utils/isMobile';
import formatDate from 'utils/formatDate';
import EditDeliveryTime from './EditDeliveryTime';

export interface FormErrors {
  nameTag: string;
}

interface LeftRight {
  left: string;
  right?: string | ReactNode;
  error?: boolean;
}

interface Props {
  index: number;
}

const ProductCard: FC<Props> = ({ index }) => {
  const { products } = FormContainer.useContainer();

  const product = products[index];
  const stroller = product.product;

  const reviewDates =
    product?.deliveryDate &&
    product?.returnDate &&
    `${formatDate(product.deliveryDate, 'MM/DD/YY')} - ${formatDate(
      product.returnDate,
      'MM/DD/YY',
    )}`;

  const deliveryDate = product.deliveryDate;
  const returnDate = product.returnDate;

  const deliveryLocation = product.deliveryLocation;
  const returnLocation = product.returnLocation;

  const deliveryDateIsToday =
    !!deliveryDate &&
    isToday(
      new DateObject({
        date: deliveryDate,
        format: 'YYYY-MM-DD',
      }).toDate(),
    );

  const deliveryLocationName = (
    location?: Form['returnLocation'] | Form['deliveryLocation'],
  ) => {
    const locationType = location?.location;

    if (locationType === LocationType.Airport) {
      return 'Orlando International Airport - MCO';
    }

    if (locationType === LocationType.VacationHome) {
      return location?.vacationHome?.streetAddress || 'Vacation Home Rental';
    }

    if (locationType === LocationType.Resort) {
      return location?.resort?.ResortName;
    }

    return '';
  };

  const renderTimeSelect = (
    location?: Form['returnLocation'] | Form['deliveryLocation'],
    isDelivery?: boolean,
  ) => {
    if (!isDelivery) {
      return location && location.time;
    }

    return <EditDeliveryTime currentProductIndex={index} />;
  };

  const renderLocation = (
    location?: Form['returnLocation'] | Form['deliveryLocation'],
    date?: Form['deliveryDate'] | Form['returnDate'],
    isDelivery?: boolean,
  ) => {
    const rows: LeftRight[] = [
      {
        left: 'Location:',
        right: deliveryLocationName(location),
      },
      {
        left: 'Date:',
        right: formatDate(date),
        error: isDelivery && deliveryDateIsToday,
      },
      {
        left: 'Time:',
        right: renderTimeSelect(location, isDelivery),
      },
    ];

    return rows.map(info => leftRight(info));
  };

  const leftRight = (info: LeftRight) => (
    <Box
      key={info.left + info.right}
      display="flex"
      flex={1}
      width="100%"
      sx={{ mt: 1, overflow: 'hidden' }}
    >
      <Box display="flex">
        <Text
          fontSize={isMobile ? 14 : 16}
          fontWeight={400}
          color="primary.darker"
        >
          {info.left}
        </Text>
      </Box>
      <Box
        display="flex"
        ml={isMobile ? 2 : 4}
        flex={1}
        justifyContent="flex-end"
      >
        <Text
          fontSize={isMobile ? 14 : 16}
          fontWeight={400}
          align="right"
          color={info.error ? 'error.main' : 'inherit'}
        >
          {info.right}
        </Text>
      </Box>
    </Box>
  );

  if (!stroller) return null;

  return (
    <>
      <ExpandableStrollerCard
        stroller={stroller}
        type={ExpandableStrollerCardType.REVIEW}
        reviewDates={reviewDates}
        readonly
        isEdit
      >
        <Box py={0} display="flex" flexDirection="column">
          <Box mt={4} px={2}>
            <Box display="flex" alignItems="center">
              <FormLabel mb={0}>Delivery Details:</FormLabel>{' '}
            </Box>

            {renderLocation(deliveryLocation, deliveryDate, true)}
          </Box>

          <Divider sx={{ mt: 2, backgroundColor: 'primary.darker' }} />

          <Box mt={2} px={2}>
            <Box display="flex" alignItems="center">
              <FormLabel mb={0}>Return Details:</FormLabel>{' '}
            </Box>

            {renderLocation(returnLocation, returnDate)}
          </Box>
        </Box>
      </ExpandableStrollerCard>
    </>
  );
};

export default ProductCard;
