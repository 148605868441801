import { FC, useEffect, PropsWithChildren, Fragment, ReactNode } from 'react';
import { Box, Divider } from '@mui/material';
import { Text } from '@concepta/react-material-ui';

import BlueCard from 'app/components/BlueCard';
import ProductCardDesktop from 'app/components/ProductCardDesktop';
import ErrorText from 'app/components/ErrorText/ErrorText';
import useScrollTop from 'app/hooks/useScrollTop';
import { FormContainer, Form, LocationType } from 'app/hooks/useGlobalForm';
import { isMobile } from 'utils/isMobile';
import FormLabel from 'app/components/FormLabel';
import formatDate from 'utils/formatDate';
import EditDeliveryTime from './EditDeliveryTime';
import differenceInHours from 'date-fns/differenceInHours';
import { DateObject } from 'react-multi-date-picker';

interface LeftRight {
  left: string;
  right?: ReactNode;
  error?: boolean;
}

interface DesktopDisplayProps {
  errorMessage?: string;
}

const DesktopDisplay: FC<PropsWithChildren<DesktopDisplayProps>> = ({
  errorMessage,
  children,
}) => {
  const {
    products,
    setCurrentProductIndex,
    currentProductIndex,
    currentProduct,
    originalProducts,
  } = FormContainer.useContainer();

  useEffect(() => {
    setCurrentProductIndex(0);
  }, []);

  useScrollTop(true);

  const deliveryLocation = currentProduct.deliveryLocation;
  const returnLocation = currentProduct.returnLocation;

  const deliveryDate = currentProduct.deliveryDate;
  const returnDate = currentProduct.returnDate;

  const deliveryLocationName = (
    location?: Form['returnLocation'] | Form['deliveryLocation'],
  ) => {
    const locationType = location?.location;

    if (locationType === LocationType.Airport) {
      return 'Orlando International Airport - MCO';
    }

    if (locationType === LocationType.VacationHome) {
      return location?.vacationHome?.streetAddress || 'Vacation Home Rental';
    }

    if (locationType === LocationType.Resort) {
      return location?.resort?.ResortName;
    }

    return '';
  };

  const renderTimeSelect = (
    location?: Form['returnLocation'] | Form['deliveryLocation'],
    isDelivery?: boolean,
  ) => {
    if (!isDelivery) {
      return location && location.time;
    }

    return <EditDeliveryTime currentProductIndex={currentProductIndex} />;
  };

  const renderLocation = (
    location?: Form['returnLocation'] | Form['deliveryLocation'],
    date?: Form['deliveryDate'] | Form['returnDate'],
    isDelivery?: boolean,
  ) => {
    const rows: LeftRight[] = [
      {
        left: 'Location:',
        right: deliveryLocationName(location),
      },
      {
        left: 'Date:',
        right: formatDate(date),
      },
      {
        left: 'Time:',
        right: renderTimeSelect(location, isDelivery),
      },
    ];

    return rows.map(info => leftRight(info));
  };

  const leftRight = (info: LeftRight) => (
    <Box
      key={info.left + info.right}
      display="flex"
      flex={1}
      width="100%"
      sx={{ mt: 1, overflow: 'hidden' }}
    >
      <Box display="flex">
        <Text
          fontSize={isMobile ? 14 : 16}
          fontWeight={400}
          color="primary.darker"
        >
          {info.left}
        </Text>
      </Box>
      <Box
        display="flex"
        ml={isMobile ? 2 : 4}
        flex={1}
        justifyContent="flex-end"
      >
        <Text
          fontSize={isMobile ? 14 : 16}
          fontWeight={400}
          align="right"
          color={info.error ? 'error.main' : 'inherit'}
        >
          {info.right}
        </Text>
      </Box>
    </Box>
  );

  const originalTime =
    originalProducts[currentProductIndex]?.deliveryLocation?.time;

  const deliveryETA =
    deliveryDate &&
    originalTime &&
    differenceInHours(
      new DateObject({
        date: `${deliveryDate} ${originalTime?.split(' - ')[0]}`,
        format: 'YYYY-MM-DD HH:mm',
      }).toDate(),
      new Date(),
    );
  const deliveryTimeIsLessThan24h = deliveryETA && deliveryETA < 24;

  return (
    <Box sx={{ display: 'flex', width: '100%', py: 4 }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          position: 'relative',
          pr: 4,
        }}
      >
        {products.map((product, i) => (
          <ProductCardDesktop
            key={`${product.product?.ProductID}${i}`}
            index={i}
            selected={currentProductIndex === i}
            setSelected={setCurrentProductIndex}
            isEdit
            preserveRoundBorder
          />
        ))}
        {deliveryTimeIsLessThan24h || errorMessage ? (
          <ErrorText>
            {deliveryTimeIsLessThan24h
              ? 'Your order will be delivered today. Please call us if you need any adjustments.'
              : errorMessage}
          </ErrorText>
        ) : (
          children
        )}
      </Box>
      <Box flex={1.4}>
        <BlueCard
          sx={{
            px: 2,
            pb: 4,
            mb: 1,
            borderRadius: '20px',
          }}
        >
          {products[currentProductIndex] && (
            <Fragment>
              <Box mt={4} px={2}>
                <Box display="flex" alignItems="center">
                  <FormLabel mb={0}>Delivery Details:</FormLabel>{' '}
                </Box>
                {renderLocation(deliveryLocation, deliveryDate, true)}
              </Box>

              <Divider sx={{ mt: 2, backgroundColor: 'primary.darker' }} />

              <Box mt={2} px={2}>
                <Box display="flex" alignItems="center">
                  <FormLabel mb={0}>Return Details:</FormLabel>{' '}
                </Box>

                {renderLocation(returnLocation, returnDate)}
              </Box>
            </Fragment>
          )}
        </BlueCard>
      </Box>
    </Box>
  );
};

export default DesktopDisplay;
