import { FC, Fragment, ReactNode } from 'react';
import { Box } from '@mui/material';
import { Text, Image } from '@concepta/react-material-ui';
import { Helmet } from 'react-helmet';

import useGetAllProducts from 'app/hooks/useGetAllProducts';
import useGetAllResorts from 'app/hooks/useGetAllResorts';
import Header from 'app/components/Header';
import Footer from 'app/components/Footer';
import Container from '@mui/material/Container';
import { isMobile } from 'utils/isMobile';
import LoginHeader from './Header';
import airport from 'assets/icons/airport.svg';
import delivery from 'assets/icons/delivery.svg';
import grocery from 'assets/icons/grocery.svg';

const ENV = process.env.REACT_APP_ENV;

interface Info {
  icon: ReactNode;
  text: string | ReactNode;
}

const Login: FC = () => {
  useGetAllProducts(); // TODO: show error message if Products or Resorts fail to load
  useGetAllResorts();

  const imageSx = {
    width: isMobile ? 46 : 58,
    height: isMobile ? 46 : 58,
  };
  const info: Info[] = [
    {
      icon: <Image src={delivery} alt="Delivery" sx={imageSx} />,
      text: 'Free Delivery & Pickup from Your Resort, Hotel or Vacation Home',
    },
    {
      icon: <Image src={airport} alt="Airport" sx={imageSx} />,
      text: 'Orlando International Airport Delivery and drop off',
    },
    {
      icon: <Image src={grocery} alt="Grocery" sx={imageSx} />,
      text: (
        <>
          Free Grocery Delivery Coupon for Disney Resort Guests{' '}
          <Text
            color="primary.darker"
            fontWeight={600}
            fontSize={isMobile ? 13 : 18}
            component={isMobile ? 'p' : 'span'}
          >
            (Provided by Orlando Grocery Express)
          </Text>
        </>
      ),
    },
  ];

  return (
    <Fragment>
      <Helmet>
        {ENV === 'PRODUCTION' && (
          <script>{`<!-- Google Tag Manager -->
(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-T9PWBRCX');
<!-- End Google Tag Manager —>`}</script>
        )}
      </Helmet>
      {ENV === 'PRODUCTION' && (
        <iframe
          src="https://www.googletagmanager.com/ns.html?id=GTM-T9PWBRCX"
          height="0"
          width="0"
          style={{ display: 'none', visibility: 'hidden' }}
        ></iframe>
      )}
      <Box minHeight="100vh">
        <Header isEdit hideLastButton />

        <LoginHeader />

        <Box display="flex" flex={1} sx={{ py: isMobile ? 4 : 8 }}>
          <Container maxWidth="lg">
            <Box
              display="flex"
              flexDirection="column"
              alignItems="flex-start"
              flex={1}
              width="100%"
            >
              <Text
                color="primary.darker"
                fontSize={27}
                mb={isMobile ? 2 : 5}
                fontWeight={400}
                align="center"
                variant="h2"
                mx="auto"
                lineHeight={1.8}
              >
                <strong>Kingdom Strollers{isMobile ? ' ' : ', '}</strong>
                {isMobile && <br />}
                Orlando&apos;s First Choice For Stroller Rentals
              </Text>

              {info.map((item, index) => (
                <Box
                  key={index}
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="flex-start"
                  flex={1}
                  width="100%"
                  sx={{ pb: isMobile ? 3 : 5 }}
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    {item.icon}
                  </Box>
                  <Text variant="body1" sx={{ ml: isMobile ? 2 : 7 }}>
                    <Text
                      color="primary.darker"
                      fontWeight={600}
                      fontSize={isMobile ? 16 : 21}
                      lineHeight={2}
                    >
                      {item.text}
                    </Text>
                  </Text>
                </Box>
              ))}
            </Box>
          </Container>
        </Box>

        <Footer />
      </Box>
    </Fragment>
  );
};

export default Login;
