import { FC, Fragment } from 'react';
import { Text } from '@concepta/react-material-ui';
import Box from '@mui/material/Box';
import LeftRightInfo from 'app/components/LeftRightInfo';
import { AccessoriesProductsDTO } from 'ks-common';
import { Form } from 'app/hooks/useGlobalForm';
import { isMobile } from 'utils/isMobile';
import sortAccessories from 'utils/sortAccessoriesByPrice';

interface Props {
  product: Partial<Form>;
  index: number;
}

const Accessories: FC<Props> = ({ product }) => {
  const allProductAccessories = product?.product?.accessoriesProducts;
  const selectedIds = product?.accessories?.accessories || [];
  const selectedAccessories = selectedIds?.map(id =>
    allProductAccessories?.find?.(
      (accssr: AccessoriesProductsDTO) => id === accssr?.AccessoriesID,
    ),
  );

  return (
    <Fragment>
      <Box mb={3}>
        {selectedAccessories?.sort(sortAccessories).map(accessory => {
          const accessoryInfo = accessory?.accessories;
          if (!accessoryInfo) return null;

          return (
            <LeftRightInfo
              key={accessory.AccessoriesID}
              sx={{ mt: 1 }}
              left={
                <Text fontSize={isMobile ? 14 : 16} fontWeight={400}>
                  {accessoryInfo.Name}
                </Text>
              }
              right={
                <Box display="flex" alignItems="center">
                  <Text fontSize={isMobile ? 14 : 16} fontWeight={400}>
                    {accessoryInfo.Price === 0
                      ? 'Free'
                      : `$${accessoryInfo.Price}`}
                  </Text>
                </Box>
              }
            />
          );
        })}
        {selectedIds?.length === 0 && (
          <Text
            fontSize={isMobile ? 14 : 16}
            fontWeight={400}
            color="grey.400"
            align={isMobile ? 'center' : 'left'}
          >
            No accessories selected
          </Text>
        )}
      </Box>
    </Fragment>
  );
};

export default Accessories;
