import { FC } from 'react';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { CustomerForm } from 'app/hooks/useOrderCheckout';
import { FormErrors } from '../validators';
import CheckoutTextField, { OnChangeTextField } from './CheckoutTextField';
import MenuItem from '@mui/material/MenuItem';
import { Text } from '@concepta/react-material-ui';
import countries from '../countries';
import { customerFormFields, FieldTypes } from '../formfields';

interface Props {
  form: Partial<CustomerForm>;
  errors: FormErrors<CustomerForm>;
  onChange: OnChangeTextField;
  onSelectChange: (event: SelectChangeEvent) => void;
  isEdit?: boolean;
}

const Customer: FC<Props> = ({
  form,
  errors,
  onChange,
  onSelectChange,
  isEdit,
}) => {
  return (
    <Grid container spacing={3}>
      {customerFormFields(isEdit).map(field => {
        const value = form[field.name as keyof CustomerForm];

        if (
          field.type === FieldTypes.TEXT &&
          (typeof value === 'string' || typeof value === 'undefined')
        ) {
          return (
            <CheckoutTextField
              key={field.name}
              label={field.label}
              name={field.name}
              value={value}
              onChange={onChange}
              required={field.required}
              maxLength={field.maxLength}
              mask={field.mask}
              error={errors[field.name]}
              disabled={field.disabled}
            />
          );
        }

        if (
          field.type === FieldTypes.SELECT &&
          field.name === 'country' &&
          (typeof value === 'string' || typeof value === 'undefined')
        ) {
          return (
            // TODO: change Select component to ROCKET'S Select, but fix variant there first
            <Grid item xs={12} key={field.name}>
              <FormControl
                variant="standard"
                fullWidth
                error={!!errors[field.name]}
              >
                <InputLabel>
                  {field.label}{' '}
                  <Text fontSize={12} display="inline">
                    (required)
                  </Text>
                </InputLabel>
                <Select
                  name={field.name}
                  onChange={onSelectChange}
                  value={value}
                  defaultValue={value}
                >
                  {countries.map(country => (
                    <MenuItem key={country.value} value={country.value}>
                      {country.label}
                    </MenuItem>
                  ))}
                </Select>
                {errors[field.name] && (
                  <FormHelperText>{errors[field.name]}</FormHelperText>
                )}
              </FormControl>
            </Grid>
          );
        }
      })}
    </Grid>
  );
};

export default Customer;
