import { CustomerForm } from 'app/hooks/useOrderUpdate';
import { CustomersUpdatebleInterface } from 'ks-common';

type CustomerParser = (customer: CustomerForm) => CustomersUpdatebleInterface;

// @ts-expect-error Setting `SecondEmail` to `null` is required to remove the e-mail
// when value is empty string.
const customerParserToUpdate: CustomerParser = customer => {
  return {
    CustomerID: customer.customerID,
    FullName: `${customer.firstName} ${customer.lastName}`,
    CellPhone: customer.primaryCellPhone,
    Address1: customer.billingAddress1,
    City: customer.city,
    State: customer.state,
    Zip: customer.zipCode,
    Country: customer.country,
    Email: customer.emailAddress,
    SecondEmail: customer?.secondaryEmail || null,
    SecondCellPhone: customer?.secondaryCellPhone || null,
    ...(!!customer.billingAddress2 && { Address2: customer.billingAddress2 }),
  };
};

export default customerParserToUpdate;
