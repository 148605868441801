import { FC, ReactNode, useState, useRef } from 'react';
import { Image, Text } from '@concepta/react-material-ui';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import DeleteOutline from '@mui/icons-material/DeleteOutline';
import Collapse from '@mui/material/Collapse';
import BlueCard from 'app/components/BlueCard';
import ExpandButton from './ExpandButton';
import { ProductInterface } from 'ks-common';
import ExpandLess from '@mui/icons-material/ExpandLess';

export enum ExpandableStrollerCardType {
  REVIEW = 'review',
  RESERVE = 'reserve',
}

interface Props {
  stroller: ProductInterface;
  type?: ExpandableStrollerCardType;
  handleActionClick?: () => void;
  reservePrice?: string;
  reviewDates?: ReactNode;
  mt?: number;
  disabled?: boolean;
  children?: ReactNode;
  initExpanded?: boolean;
  readonly?: boolean;
  isEdit?: boolean;
}

const ExpandableStrollerCard: FC<Props> = ({
  stroller,
  type = ExpandableStrollerCardType.REVIEW,
  handleActionClick,
  reservePrice,
  reviewDates,
  mt = 2,
  disabled,
  initExpanded = false,
  readonly,
  isEdit,
  children,
}) => {
  const theme = useTheme();

  const itemRef = useRef<null | HTMLDivElement>(null);

  const [expanded, setExpanded] = useState(initExpanded);

  const strollerImg = stroller?.productImages?.[0]?.URL;

  const handleViewMore = () => {
    setExpanded(!expanded);
    if (!expanded) {
      const dims = itemRef?.current?.offsetTop || 0;
      window.scrollTo({
        left: window.scrollX,
        top: dims - 80,
        behavior: 'smooth',
      });
    }
  };

  const isReview = type === ExpandableStrollerCardType.REVIEW;
  const isReserve = type === ExpandableStrollerCardType.RESERVE;

  return (
    <Box ref={itemRef}>
      <BlueCard
        sx={{
          mt,
          py: 2,
        }}
      >
        <Box sx={{ opacity: disabled ? 0.5 : 1 }}>
          <Box display="flex" justifyContent="center" px={2}>
            <Box width="88px" height="88px" mr={3}>
              {strollerImg && (
                <Image src={strollerImg} alt="Stroller preview" imgFluid />
              )}
            </Box>

            <Box display="flex" flexDirection="column" flex={1}>
              <Text
                fontSize={20}
                fontWeight={700}
                color={disabled ? 'grey.600' : 'primary.darker'}
              >
                {stroller?.ProductName}
              </Text>

              {isReview && (
                <Text fontSize={14} fontWeight={600} color="grey.400">
                  {reviewDates}
                </Text>
              )}

              {isReserve && (
                <Text
                  fontSize={16}
                  fontWeight={600}
                  color={disabled ? 'grey.600' : 'primary.darker'}
                >
                  {reservePrice}
                </Text>
              )}

              <Box sx={{ mt: 'auto' }} display="flex" alignItems="center">
                {((isReserve && !expanded) || isReview) && (
                  <ExpandButton
                    handleViewMore={handleViewMore}
                    expanded={expanded}
                    disabled={disabled}
                  />
                )}

                {isReview && !readonly && !isEdit && (
                  <Box
                    ml="auto"
                    display="flex"
                    alignItems="center"
                    {...(!disabled && { onClick: handleActionClick })}
                  >
                    <Text fontSize={12} fontWeight={500} color="grey.400">
                      Remove
                    </Text>
                    <DeleteOutline
                      sx={{
                        fontSize: '18px',
                        color: disabled ? 'grey.600' : 'primary.darker',
                      }}
                    />
                  </Box>
                )}

                {isReserve && (
                  <Button
                    variant="contained"
                    sx={{
                      py: '3px',
                      px: '3px',
                      ml: 'auto',
                      textTransform: 'none',
                      ...(disabled && {
                        backgroundColor: `${theme.palette.grey[600]} !important`,
                        color: `${theme.palette.common.white} !important`,
                      }),
                    }}
                    onClick={handleActionClick}
                    disabled={disabled}
                  >
                    {disabled ? 'Unavailable' : 'Reserve'}
                  </Button>
                )}
              </Box>
            </Box>
          </Box>

          <Box display="flex" flexDirection="column">
            <Collapse in={expanded} timeout="auto" unmountOnExit>
              {children}
              {isReserve && expanded && (
                <Box display="flex" justifyContent="center">
                  <ExpandButton
                    handleViewMore={handleViewMore}
                    expanded={expanded}
                    disabled={disabled}
                  />
                </Box>
              )}

              {isReview && expanded && (
                <Box
                  display="flex"
                  justifyContent="center"
                  onClick={handleViewMore}
                >
                  <ExpandLess
                    sx={{
                      fontSize: '34px',
                      color: disabled ? 'grey.600' : 'primary.darker',
                    }}
                  />
                </Box>
              )}
            </Collapse>
          </Box>
        </Box>
      </BlueCard>
    </Box>
  );
};

export default ExpandableStrollerCard;
