import { useState } from 'react';
import { createContainer, Container } from 'unstated-next';
import useLocalStorageState from 'use-local-storage-state';
import mergeWith from 'lodash/mergeWith';
import cloneDeep from 'lodash/cloneDeep';
import {
  OrderEditLoadInterface,
  VacationHomeInterface,
  ProductInterface,
} from 'ks-common';

import useGetAllProducts from 'app/hooks/useGetAllProducts';
import useGetAllResorts from 'app/hooks/useGetAllResorts';
import mergeArrayCustomizer from 'utils/mergeArrayCustomizer';
import useGlobalFormValidations from './useGlobalFormValidations';
import {
  Form,
  UpdateForm,
  SetCurrentProduct,
  LocationType,
  VacationHome,
} from './useGlobalForm';
import { CommonReturn } from './useGlobalForm';
import { CustomerForm } from './useOrderUpdate';
import generateUuid from 'utils/generateUuid';

export type { CustomerForm };

export type EditFormContainerReturn = CommonReturn & {
  originalOrder: OrderEditLoadInterface | null;
  setOriginalOrder: React.Dispatch<OrderEditLoadInterface>;
  originalProducts: Partial<Form>[];
  setOriginalProducts: React.Dispatch<Partial<Form>[]>;
  customerInfo: Partial<CustomerForm> | null;
  setCustomerInfo: React.Dispatch<Partial<CustomerForm> | null>;
  originalCustomerInfo: Partial<CustomerForm> | null;
  setOriginalCustomerInfo: React.Dispatch<Partial<CustomerForm> | null>;
};

const useForm = () => {
  const { allProducts } = useGetAllProducts();
  const { data: resortsList } = useGetAllResorts();

  const [originalOrder, _setOriginalOrder] =
    useState<OrderEditLoadInterface | null>(null);
  const [originalProducts, setOriginalProducts] = useState<Partial<Form>[]>([]);
  const [products, setProducts] = useState<Partial<Form>[]>([]);
  const [currentProductIndex, setCurrentProductIndex] = useState<number>(0);
  const [customerInfo, setCustomerInfo] =
    useState<Partial<CustomerForm> | null>(null);
  const [originalCustomerInfo, setOriginalCustomerInfo] =
    useState<Partial<CustomerForm> | null>(null);

  const currentProduct = products[currentProductIndex];

  const setCurrentProduct: SetCurrentProduct = product => {
    const newArr = [...products];
    newArr[currentProductIndex] = product;
    setProducts(newArr);
  };

  const updateCurrentProduct: UpdateForm = (
    value,
    index = currentProductIndex,
  ) => {
    let indexToUse = index;

    if (!products[indexToUse]) {
      indexToUse = products.length;
      setCurrentProductIndex(indexToUse);
    }

    const currentProduct = products[indexToUse] || { step: 0 };
    const updatedProduct = {
      ...mergeWith(currentProduct, value, mergeArrayCustomizer),
    };

    const newArr = [...products];
    newArr[indexToUse] = updatedProduct;
    setProducts(newArr);
  };

  const [orderQuoteId, setOrderQuoteId] =
    useLocalStorageState<string>('orderQuoteId');

  const clearForm = () => {
    setProducts([]);
    setOriginalProducts([]);
    setCurrentProductIndex(0);
    setCustomerInfo(null);
    setOriginalCustomerInfo(null);
    setOrderQuoteId(generateUuid());
  };

  const validations = useGlobalFormValidations(products, currentProduct);

  const formatOriginalCustomerToFormCustomer = (
    customer: OrderEditLoadInterface['Customer'],
  ) => ({
    customerID: customer.CustomerID,
    firstName: customer.FullName.split(' ')[0],
    lastName: customer.FullName.split(' ')[1],
    billingAddress1: customer.Address1,
    billingAddress2: customer.Address2 || '',
    city: customer.City,
    state: customer.State,
    country: customer.Country,
    zipCode: customer.Zip,
    primaryCellPhone: customer.CellPhone,
    confirmPrimaryCellPhone: '',
    secondaryCellPhone: customer.SecondCellPhone || '',
    emailAddress: customer.Email,
    confirmEmail: '',
    secondaryEmail: customer.SecondEmail || '',
    rentalAgreement: false,
  });

  const formatOriginalProductsToFormProducts = (
    products: OrderEditLoadInterface['OrderQuoteOutput']['Items'],
  ) => {
    const deliveryLocationType = (id: number) => {
      if (id === 8415) {
        return LocationType['Airport'];
      }
      if (id === 243) {
        return LocationType['VacationHome'];
      }
      return LocationType['Resort'];
    };

    const vacationHome = (product?: VacationHomeInterface) => {
      const vacHome: VacationHome = {
        city: product?.City || '',
        gateCode: product?.Gate || '',
        state: product?.State || '',
        streetAddress: product?.Street || '',
        zip: product?.Zip || '',
      };

      return vacHome;
    };

    const findResortData = (id: number) => {
      const resortData = resortsList?.find(resort => resort.ResortId === id);
      // If the resort is airtport or vacation home, prevent data in the resort param
      if (id === 8415 || id === 243) {
        return undefined;
      }

      return resortData;
    };

    return products?.map(product => {
      const accessories = product.Accessories.map(
        accessory => accessory.AccessoriesID,
      );
      const insurance = product.Insurance;
      const nameTag = product.NameTag;
      const commentsAndNotes = product.SpecialNotes;
      const deliveryLocation = {
        location: cloneDeep(deliveryLocationType(product.DeliveryResortId)),
        resort: cloneDeep(findResortData(product.DeliveryResortId)), // get complete resort data
        vacationHome: cloneDeep(
          vacationHome(product.DeliveryVacationHomeAddress),
        ),
        time: product.DeliveryTime,
      };
      const returnLocation = {
        sameAsCheckin: product.PickUpResortId === product.DeliveryResortId,
        location: cloneDeep(deliveryLocationType(product.PickUpResortId)),
        resort: cloneDeep(findResortData(product.PickUpResortId)),
        vacationHome: cloneDeep(
          vacationHome(product.PickUpVacationHomeAddress),
        ),
        time: product.PickUpTime,
      };

      const displayProduct = allProducts?.find(
        p => p.ProductID === product.ProductId,
      ) as ProductInterface;

      if (!displayProduct) {
        throw new Error('Product not found');
      }

      const productForm: Form = {
        step: 2,
        deliveryDate: (product.DeliveryDate as unknown as string)?.split(
          'T',
        )[0],
        returnDate: (product.PickUpDate as unknown as string)?.split('T')[0],
        product: displayProduct,
        accessories: {
          accessories,
          insurance,
          nameTag,
          commentsAndNotes,
        },
        deliveryLocation,
        returnLocation,
        orderProductId: product.OrderProductId,
        // TODO: Check if we need billing
        // billing: {
        //   rental: product.TotalItemValue,
        //   tax: product.PremiumFee,
        // },
      };

      return productForm;
    });
  };

  const setOriginalOrder = (order: OrderEditLoadInterface) => {
    _setOriginalOrder(order);
    setOriginalProducts(
      formatOriginalProductsToFormProducts(order.OrderQuoteOutput.Items),
    );
    setProducts(
      formatOriginalProductsToFormProducts(order.OrderQuoteOutput.Items),
    );
    setCustomerInfo(formatOriginalCustomerToFormCustomer(order.Customer));
    setOriginalCustomerInfo(
      formatOriginalCustomerToFormCustomer(order.Customer),
    );
  };

  return {
    originalOrder,
    setOriginalOrder,
    products,
    setProducts,
    originalProducts,
    setOriginalProducts,
    currentProduct,
    setCurrentProduct,
    updateCurrentProduct,
    currentProductIndex,
    setCurrentProductIndex,
    clearForm,
    customerInfo,
    setCustomerInfo,
    originalCustomerInfo,
    setOriginalCustomerInfo,
    orderQuoteId,
    setOrderQuoteId,
    validations,
  };
};

export const EditFormContainer: Container<EditFormContainerReturn, void> =
  createContainer<EditFormContainerReturn, void>(useForm);
