import useDataProvider, { useQuery } from '@concepta/react-data-provider';
import { Form } from './useGlobalForm';
import {
  OrderUpdateInputInterface,
  OrderQuoteItemInputInterface,
  OrderCheckoutOutputInterface,
} from 'ks-common';
import productParserToCheckout from 'utils/productParserToCheckout';
import customerParserToUpdate from 'utils/customerParserToUpdate';
import { toast } from 'react-toastify';
import { addBreadcrumb, captureException } from '@sentry/react';

export interface CustomerForm {
  customerID: number;
  firstName: string;
  lastName: string;
  billingAddress1: string;
  billingAddress2: string;
  city: string;
  state: string;
  country: string;
  zipCode: string;
  primaryCellPhone: string;
  confirmPrimaryCellPhone: string;
  secondaryCellPhone: string;
  emailAddress: string;
  confirmEmail: string;
  secondaryEmail: string;
  rentalAgreement: boolean;
}

interface Props {
  orderId?: number;
  products: Partial<Form>[];
  paymentInfo?: OrderUpdateInputInterface['PaymentInfo'];
  customer: CustomerForm;
  orderQuoteId?: string;
  onSuccess?: (data: OrderCheckoutOutputInterface) => void;
  requestUrl?: string;
  successMessage?: string;
  errorMessage?: string;
}

const useOrderUpdate = ({
  orderId,
  products,
  paymentInfo,
  customer,
  orderQuoteId,
  onSuccess,
  requestUrl,
  successMessage,
  errorMessage,
}: Props) => {
  const { post } = useDataProvider();

  // TODO: type as OrderUpdateInputInterface once it's fixed
  const body = {
    OrderId: orderId as number,
    OrderQuote: {
      OrderId: orderId as number,
      Items: products
        .map(product => productParserToCheckout(product))
        .filter(item => item !== undefined) as OrderQuoteItemInputInterface[],
      OrderQuoteId: orderQuoteId as string,
    },
    Customer: customerParserToUpdate(customer),
    ...(paymentInfo && { PaymentInfo: paymentInfo }),
  };

  const postOrderUpdate = () => {
    return post({
      uri: requestUrl || 'order-update',
      body: body as unknown as OrderUpdateInputInterface,
    });
  };

  const { data, execute, isPending, error } = useQuery(postOrderUpdate, false, {
    onError: errorResponse => {
      // Todo: Fix error response in rockets
      const errorRes = errorResponse as unknown as {
        response: { data: { message: string } };
      };

      toast.error(
        errorMessage ||
          errorRes?.response?.data?.message ||
          errorResponse?.message,
      );

      captureException({
        category: 'OrderUpdate',
        message: 'OrderUpdate error',
        data: body,
        error: errorResponse,
      });
    },
    onSuccess: (data: OrderCheckoutOutputInterface) => {
      if (data.OrderQuoteCalculated.PendingAmountValue === 0) {
        toast.success(successMessage || 'Your order was successfully updated');
      }
      if (data.OrderQuoteCalculated.PendingAmountValue < 0) {
        toast.success(
          successMessage ||
            'Your refund request was successfully received. Please allow 3-5 business days to process.',
        );
      }

      addBreadcrumb({
        category: 'OrderUpdate',
        message: 'OrderUpdate success',
        data: body,
      });
      onSuccess?.(data);
    },
  });

  return { data, execute, isPending, error };
};

export default useOrderUpdate;
