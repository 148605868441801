import { FC, useEffect, useMemo } from 'react';
import { Box, Skeleton, MenuItem, TextField } from '@mui/material';
import { VersionTimesInterface } from 'ks-common';
import format from 'date-fns/format';
import isArray from 'lodash/isArray';
import { DateObject } from 'react-multi-date-picker';
import differenceInHours from 'date-fns/differenceInHours';

import { FormContainer, LocationType } from 'app/hooks/useGlobalForm';
import useGetResortData from 'app/hooks/useGetResortData';

type WeekDates = 'Sun' | 'Mon' | 'Tue' | 'Wed' | 'Thu' | 'Fri' | 'Sat';

interface Props {
  currentProductIndex: number;
}

const EditDeliveryTime: FC<Props> = ({ currentProductIndex }) => {
  const { products, updateCurrentProduct, originalProducts } =
    FormContainer.useContainer();
  const currentProduct = products[currentProductIndex];
  const { deliveryDate, deliveryLocation } = currentProduct;

  const originalTime =
    originalProducts[currentProductIndex]?.deliveryLocation?.time;

  const deliveryIsDisney = Number(deliveryLocation?.resort?.ResortType) === 1;

  const weekDay =
    deliveryDate &&
    format(
      new DateObject({
        date: deliveryDate,
        format: 'YYYY-MM-DD',
      }).toDate(),
      'iii',
    );

  const deliveryETA =
    currentProduct.deliveryDate &&
    originalTime &&
    differenceInHours(
      new DateObject({
        date: `${currentProduct.deliveryDate} ${originalTime?.split(' - ')[0]}`,
        format: 'YYYY-MM-DD HH:mm',
      }).toDate(),
      new Date(),
    );
  const deliveryTimeIsLessThan24h = deliveryETA && deliveryETA < 24;

  const deliveryResortId = () => {
    const locationType = deliveryLocation?.location;
    const isVacationHome = locationType === LocationType.VacationHome;
    const isAirport = locationType === LocationType.Airport;

    if (isAirport) {
      return 8415;
    }
    if (isVacationHome) {
      return 243;
    }

    return deliveryLocation?.resort?.ResortId;
  };

  const {
    data: resortData,
    execute,
    isPending,
    error,
  } = useGetResortData(deliveryResortId());

  useEffect(() => {
    execute();
  }, [currentProduct]);

  const weekTimes: VersionTimesInterface | undefined = useMemo(() => {
    const timeSchedule = resortData?.TimeSchedule;
    if (typeof timeSchedule !== 'string') {
      const times = timeSchedule?.Versions?.[0]?.Times;
      return times;
    }
  }, [resortData]);

  const type = 'Delivery';
  const timesKey: keyof VersionTimesInterface | undefined =
    type && (weekDay as WeekDates) && `${type}${weekDay as WeekDates}`;

  const timeOptions = weekTimes && timesKey && weekTimes[timesKey];

  useEffect(() => {
    const currentValue = currentProduct?.deliveryLocation?.time;

    if (
      currentValue &&
      timeOptions &&
      !isPending &&
      resortData.ResortId === currentProduct.deliveryLocation?.resort?.ResortId
    ) {
      if (!timeOptions?.includes(currentValue)) {
        updateCurrentProduct?.(
          {
            deliveryLocation: {
              time: null,
            },
          },
          currentProductIndex,
        );
      }
    }
  }, [timeOptions]);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    updateCurrentProduct?.(
      {
        deliveryLocation: {
          time: event.target.value,
        },
      },
      currentProductIndex,
    );
  };

  const value = currentProduct?.deliveryLocation?.time;

  const editTimeOptions = () => {
    if (!isArray(timeOptions) || !originalTime) return [];

    const originalTimeIndex = timeOptions.findIndex(
      time => time === originalTime,
    );

    if (originalTimeIndex === -1) return [];

    return timeOptions
      .slice(originalTimeIndex)
      .map((opt: string, i: number) => {
        return (
          <MenuItem key={i} value={opt}>
            {opt}
          </MenuItem>
        );
      });
  };

  if (!timeOptions || isPending)
    return (
      <Box>
        <Skeleton width={60} height={24} />
        <Skeleton width={160} height={32} />
      </Box>
    );

  return (
    <TextField
      select
      label="Time"
      value={value}
      error={!!error}
      onChange={onChange}
      sx={{ width: '100%', mt: 1, minWidth: 160, textAlign: 'left' }}
      variant="standard"
      disabled={!deliveryIsDisney || !!error || !!deliveryTimeIsLessThan24h}
    >
      {editTimeOptions()}
    </TextField>
  );
};

export default EditDeliveryTime;
