type GenerateUuid = (isEdit?: boolean) => string;

const generateUuid: GenerateUuid = isEdit => {
  const cryptoUuid = window?.crypto?.randomUUID?.();
  if (cryptoUuid) {
    return `${isEdit ? 'edit-' : ''}${cryptoUuid}`;
  }

  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
    const r = (Math.random() * 16) | 0;
    const v = c === 'x' ? r : (r & 0x3) | 0x8;
    return `${isEdit ? 'edit-' : ''}${v.toString(16)}`;
  });
};

export default generateUuid;
