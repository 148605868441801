import { FC } from 'react';
import { Box } from '@mui/material';
import {
  OrderCheckoutOutputInterface,
  OrderEditLoadInterface,
} from 'ks-common';
import cloneDeep from 'lodash/cloneDeep';
import useOrderUpdate, { CustomerForm } from 'app/hooks/useOrderUpdate';
import Header from 'app/components/Header';
import Footer from 'app/components/Footer';
import Container from '@mui/material/Container';
import { Button } from '@mui/material';

import { EditFormContainer } from 'app/hooks/useEditForm';
import { isMobile } from 'utils/isMobile';
import OrderInfoHeader from './OrderInfoHeader';
import DesktopDisplay from './DesktopDisplay';
import MobileDisplay from './MobileDisplay';

interface UpdateButtonProps {
  onClick: () => void;
  disabled: boolean;
}

const UpdateButton = ({ onClick, disabled }: UpdateButtonProps) => (
  <Button
    variant="contained"
    onClick={onClick}
    disabled={disabled}
    sx={!isMobile ? { width: '260px', mx: 'auto' } : { width: '90%' }}
  >
    Update
  </Button>
);

const EditTime: FC = () => {
  const { products, originalOrder, customerInfo, setOriginalOrder } =
    EditFormContainer.useContainer();

  const orderAdminBlock = originalOrder?.OrderBlock === 2;
  const errorMessage =
    (orderAdminBlock &&
      'This order cannot be changed here - please contact us by email.') ||
    undefined;

  const { execute: executeUpdate, isPending: updateIsPending } = useOrderUpdate(
    {
      orderId: originalOrder?.OrderId,
      products,
      customer: customerInfo as CustomerForm,
      onSuccess: (order: OrderCheckoutOutputInterface) => {
        if (originalOrder && order) {
          const newOrderData: OrderEditLoadInterface = cloneDeep(originalOrder);
          newOrderData.OrderQuoteOutput = order.OrderQuoteCalculated;
          newOrderData.Customer = {
            ...newOrderData.Customer,
            ...order.Customer,
          };

          setOriginalOrder(newOrderData);
        }
      },
      requestUrl: 'order-update-time',
      successMessage: 'You order was successfully updated',
      errorMessage: 'You order could not be updated. Please try again.',
    },
  );

  return (
    <Box>
      <Header isEdit />
      <Container maxWidth="xl">
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: isMobile ? 'center' : 'flex-start',
            flex: 1,
            width: '100%',
            mb: 4,
          }}
        >
          <OrderInfoHeader />

          {isMobile ? (
            <>
              <MobileDisplay errorMessage={errorMessage}>
                <UpdateButton
                  onClick={executeUpdate}
                  disabled={updateIsPending}
                />
              </MobileDisplay>
            </>
          ) : (
            <DesktopDisplay errorMessage={errorMessage}>
              <UpdateButton
                onClick={executeUpdate}
                disabled={updateIsPending}
              />
            </DesktopDisplay>
          )}
        </Box>
      </Container>

      <Footer />
    </Box>
  );
};

export default EditTime;
