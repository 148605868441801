import { FC, useEffect, PropsWithChildren } from 'react';
import { Box } from '@mui/material';
import { DateObject } from 'react-multi-date-picker';

import useScrollTop from 'app/hooks/useScrollTop';
import { FormContainer } from 'app/hooks/useGlobalForm';
import ProductCard from './ProductCard';
import { differenceInHours } from 'date-fns';
import ErrorText from 'app/components/ErrorText';

interface MobileDisplayProps {
  errorMessage?: string;
}

const MobileDisplay: FC<PropsWithChildren<MobileDisplayProps>> = ({
  errorMessage,
  children,
}) => {
  const {
    products,
    setCurrentProductIndex,
    currentProduct,
    currentProductIndex,
    originalProducts,
  } = FormContainer.useContainer();

  const deliveryDate = currentProduct.deliveryDate;
  const originalTime =
    originalProducts[currentProductIndex]?.deliveryLocation?.time;

  const deliveryETA =
    deliveryDate &&
    originalTime &&
    differenceInHours(
      new DateObject({
        date: `${deliveryDate} ${originalTime?.split(' - ')[0]}`,
        format: 'YYYY-MM-DD HH:mm',
      }).toDate(),
      new Date(),
    );
  const deliveryTimeIsLessThan24h = deliveryETA && deliveryETA < 24;

  useEffect(() => {
    setCurrentProductIndex(0);
  }, []);

  useScrollTop(true);

  if (!products?.length) return null;

  return (
    <>
      <Box pb={4} width="100%">
        {products.length &&
          products.map((_, i) => {
            return <ProductCard key={i} index={i} />;
          })}
      </Box>
      {deliveryTimeIsLessThan24h || errorMessage ? (
        <ErrorText>
          {deliveryTimeIsLessThan24h
            ? 'Your order will be delivered today. Please call us if you need any adjustments.'
            : errorMessage}
        </ErrorText>
      ) : (
        children
      )}
    </>
  );
};

export default MobileDisplay;
