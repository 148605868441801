import { useState, useEffect } from 'react';
import useDataProvider, { useQuery } from '@concepta/react-data-provider';
import { FormContainer } from './useGlobalForm';
import { TapfiliateCreateCommissionInterface } from 'ks-common';
import Tap from '@tapfiliate/tapfiliate-js';
import priceMask from 'utils/priceMask';
import formatDate from 'utils/formatDate';
import { toast } from 'react-toastify';

type TapfiliateComissions = {
  id: number;
  affiliate: {
    id: string;
  };
};

// Tapfiliate lib does not have types
type TapfiliateResult = {
  commissions: TapfiliateComissions[];
  external_id: number;
  id: number;
};

interface Props {
  orderId: number;
  orderAmount: number;
  billingName: string;
}

interface Return {
  sendComission: () => void;
  data: unknown;
  isPending: boolean;
  status: unknown;
}

type UserOrderQuote = (args: Props) => Return;

const useTapConversion: UserOrderQuote = ({
  orderId,
  orderAmount,
  billingName,
}) => {
  const [isPending, setIsPending] = useState(false);
  const [comissionResult, setComissionResult] = useState<TapfiliateResult>();

  const { products } = FormContainer.useContainer();
  const { post } = useDataProvider();

  const postOrderQuote = () => {
    let body: TapfiliateCreateCommissionInterface | undefined = undefined;

    if (comissionResult) {
      body = {
        TapfiliateId: comissionResult?.commissions?.[0]?.affiliate.id,
        OrderID: comissionResult?.external_id,
        CommissionId: `${comissionResult?.commissions?.[0]?.id}`,
        AffiliateTransactionId: `${comissionResult?.id}`,
      };
    }

    return post({
      uri: 'tapfiliate/order-comission',
      body,
    });
  };

  const {
    data,
    execute,
    isPending: postIsPending,
    status,
  } = useQuery(postOrderQuote, false, {
    onError: error => {
      // Todo: Fix error response in rockets
      const errorRes = error as unknown as {
        response: { data: { message: string } };
      };

      toast.error(errorRes?.response?.data?.message || error?.message);
    },
  });

  useEffect(() => {
    if (comissionResult) {
      execute();
    }
  }, [comissionResult]);

  const conversionCallback = (errors: unknown, result: TapfiliateResult) => {
    setIsPending(false);
    setComissionResult(result);
  };

  const sendComission = async () => {
    const options = {
      always_callback: true,
      meta_data: {
        Orderid: orderId,
        Order_Amount: priceMask(orderAmount * 100, true),
        Products: products.map(product => product.product?.ProductName).join(),
        Family_Tag: products[0].accessories?.nameTag,
        Billing_Name: billingName,
        Delivery_Date: formatDate(products[0].deliveryDate),
      },
    };

    setIsPending(true);
    Tap.conversion(orderId, orderAmount, options, null, conversionCallback);
  };

  return { sendComission, data, isPending: isPending || postIsPending, status };
};

export default useTapConversion;
