import { useState, useEffect } from 'react';
import { createContainer, Container } from 'unstated-next';
import useLocalStorageState from 'use-local-storage-state';
import differenceInHours from 'date-fns/differenceInHours';
import mergeWith from 'lodash/mergeWith';
import cloneDeep from 'lodash/cloneDeep';

import mergeArrayCustomizer from 'utils/mergeArrayCustomizer';
import useGlobalFormValidations from './useGlobalFormValidations';
import {
  CommonReturn,
  Form,
  UpdateForm,
  SetCurrentProduct,
} from './useGlobalForm';
import useCheckoutLocalStorage from './useCheckoutLocalStorage';
import generateUuid from 'utils/generateUuid';

export type StartNewProduct = (value?: Partial<Form>) => void;

export type CreateFormContainerReturn = CommonReturn & {
  startNewProduct: StartNewProduct;
  removeProduct: (index: number) => void;
  thankYouProducts: Partial<Form>[];
  setThankYouProducts: React.Dispatch<Partial<Form>[]>;
};

const useForm = () => {
  const [storageProductsArray, setStorageProductsArray] = useLocalStorageState<
    Partial<Form>[]
  >('storageProductsArray');
  const [products, setProducts] = useState<Partial<Form>[]>([]);
  const [thankYouProducts, setThankYouProducts] = useState<Partial<Form>[]>([]);
  const [currentProductIndex, setCurrentProductIndex] = useState<number>(0);
  const [lastSession, setLastSession] = useLocalStorageState<Date | null>(
    'lastSession',
  );
  const [, setTapfiliateVid] = useLocalStorageState<string>('tapVid');
  const { clearCheckoutForm } = useCheckoutLocalStorage();

  const [orderQuoteId, setOrderQuoteId] =
    useLocalStorageState<string>('orderQuoteId');

  const setLastSessionNow = () => {
    setLastSession(new Date());
  };

  const currentProduct = products[currentProductIndex];
  const setCurrentProduct: SetCurrentProduct = product => {
    const newArr = [...products];
    newArr[currentProductIndex] = product;
    setProducts(newArr);
    setStorageProductsArray(newArr);
  };

  useEffect(() => {
    if (lastSession) {
      const hoursDifference = differenceInHours(
        new Date(),
        new Date(lastSession),
      );

      if (hoursDifference >= 1) {
        clearForm();
      } else {
        if (storageProductsArray) {
          setProducts(storageProductsArray);
        }
      }
    }

    setLastSessionNow();

    const handleBeforeUnload = () => {
      setLastSessionNow();
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    if (!orderQuoteId || orderQuoteId?.includes('edit-')) {
      setOrderQuoteId(generateUuid());
    }

    setLastSessionNow();
  }, [products, currentProductIndex]);

  useEffect(() => {
    if (storageProductsArray?.length === 0 && products.length > 0) {
      setProducts([]);
    }
  }, [storageProductsArray]);

  const updateCurrentProduct: UpdateForm = (
    value,
    index = currentProductIndex,
    saveToLocalStorage = true,
  ) => {
    let indexToUse = index;

    if (!products[indexToUse]) {
      indexToUse = products.length;
      setCurrentProductIndex(indexToUse);
    }

    const currentProduct = products[indexToUse] || { step: 0 };
    const updatedProduct = {
      ...mergeWith(currentProduct, value, mergeArrayCustomizer),
    };

    const newArr = [...products];
    newArr[indexToUse] = updatedProduct;
    setProducts(newArr);

    if (saveToLocalStorage) {
      setStorageProductsArray(newArr);
    }
  };

  const startNewProduct: StartNewProduct = value => {
    const firstProduct = products[0];
    let newProductData: Partial<Form> = {
      step: 0,
    };

    if (firstProduct) {
      newProductData = {
        ...newProductData,
        deliveryDate: cloneDeep(products[0].deliveryDate),
        returnDate: cloneDeep(products[0].returnDate),
        deliveryLocation: cloneDeep(products[0].deliveryLocation),
        returnLocation: cloneDeep(products[0].returnLocation),
        accessories: { nameTag: products[0].accessories?.nameTag },
      };
    }

    if (value) {
      newProductData = {
        ...newProductData,
        ...value,
      };
    }

    const newArr = [...products];
    newArr.push(newProductData);
    setProducts(newArr);
    setStorageProductsArray(newArr);
    setCurrentProductIndex(newArr.length ? newArr.length - 1 : 0);
  };

  const removeProduct = (index: number) => {
    const newArr = [...products];
    newArr.splice(index, 1);

    setProducts(newArr);
    setStorageProductsArray(newArr);
    setCurrentProductIndex(newArr.length ? newArr.length - 1 : 0);
  };

  const clearForm = () => {
    setProducts([]);
    setOrderQuoteId(generateUuid());
    setCurrentProductIndex(0);
    setStorageProductsArray([]);
    setTapfiliateVid('');
    clearCheckoutForm();
  };

  const validations = useGlobalFormValidations(products, currentProduct);

  return {
    products,
    setProducts,
    currentProduct,
    setCurrentProduct,
    updateCurrentProduct,
    startNewProduct,
    removeProduct,
    currentProductIndex,
    setCurrentProductIndex,
    clearForm,
    thankYouProducts,
    setThankYouProducts,
    orderQuoteId,
    setOrderQuoteId,
    validations,
  };
};

export const CreateFormContainer: Container<CreateFormContainerReturn, void> =
  createContainer<CreateFormContainerReturn, void>(useForm);
