import useDataProvider, { useQuery } from '@concepta/react-data-provider';
import { toast } from 'react-toastify';
import { OrderEditLoadInterface } from 'ks-common';

interface Props {
  id?: number;
  onSuccess?: (data: OrderEditLoadInterface) => void;
  onError?: () => void;
}

const useGetOrder = ({ id, onSuccess, onError }: Props) => {
  const { get } = useDataProvider();

  const getOrder = () => {
    return get({
      uri: `/order/${id}`,
    });
  };

  const { data, execute, isPending, error } = useQuery(getOrder, false, {
    onError: () => {
      toast.error('Error fetching order, please try again.');
      onError && onError();
    },
    onSuccess: data => {
      onSuccess && onSuccess(data);
    },
  });

  return { data, execute, isPending, error };
};

export default useGetOrder;
