import { FC, Fragment, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Text } from '@concepta/react-material-ui';
import Header from 'app/components/Header';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import { FormContainer } from 'app/hooks/useGlobalForm';
import ProductCard from 'app/components/ProductCard/ProductCard';
import CostSummary from 'app/components/CostSummary/CostSummary';
import ErrorText from 'app/components/ErrorText/ErrorText';
import isToday from 'date-fns/isToday';
import { OrderQuoteOutputInterface } from 'ks-common';
import { DateObject } from 'react-multi-date-picker';
import useScrollTop from 'app/hooks/useScrollTop';

interface Props {
  orderQuoteCalculated?: OrderQuoteOutputInterface;
  isPending?: boolean;
}

const ReviewOrderMobile: FC<Props> = ({ orderQuoteCalculated, isPending }) => {
  const {
    products,
    setProducts,
    startNewProduct,
    setCurrentProductIndex,
    validations,
  } = FormContainer.useContainer();
  const navigate = useNavigate();

  useScrollTop(true);

  const handleAddRental = () => {
    startNewProduct();
    navigate('/stroller-list');
  };

  useEffect(() => {
    products.length && setCurrentProductIndex(products.length - 1);
  }, []);

  useEffect(() => {
    const filteredProducts = products.filter(product => product.step === 2);
    if (filteredProducts.length !== products.length) {
      setProducts(filteredProducts);
      filteredProducts.length &&
        setCurrentProductIndex?.(filteredProducts.length - 1);
    }
  }, [products]);

  const goToCheckout = () => {
    navigate('/checkout');
  };

  const deliveryDateIsToday = products.some(
    prod =>
      !!prod.deliveryDate &&
      isToday(
        new DateObject({
          date: prod.deliveryDate,
          format: 'YYYY-MM-DD',
        }).toDate(),
      ),
  );

  const disabled = deliveryDateIsToday || validations.nameTagsError;

  return (
    <Box minHeight="100vh" width="100vw" display="flex" flexDirection="column">
      <Header backButton />

      <Text
        fontWeight={700}
        fontSize={24}
        color="primary.darker"
        align="center"
        mt={4}
      >
        Review Order
      </Text>

      <Box flex={1} px={2} pb={4}>
        {!!products.length && (
          <Fragment>
            {products.map((product, i) => (
              <ProductCard
                key={`${product.product?.ProductID}${i}`}
                product={product}
                index={i}
              />
            ))}

            {!deliveryDateIsToday && (
              <CostSummary
                quoteData={orderQuoteCalculated}
                isPending={isPending}
                isThankYouScreen
              />
            )}

            {deliveryDateIsToday && (
              <ErrorText align="center">
                Please review and enter a valid delivery date.
              </ErrorText>
            )}

            <Button
              variant="contained"
              fullWidth
              sx={{ mt: 6 }}
              onClick={goToCheckout}
              disabled={disabled}
            >
              RESERVE MY ORDER NOW
            </Button>
            <Button
              variant="outlined"
              fullWidth
              sx={{
                mt: 1,
                fontWeight: 700,
              }}
              onClick={handleAddRental}
            >
              ADD ANOTHER RENTAL
            </Button>
          </Fragment>
        )}

        {!products.length && (
          <Box
            display="flex"
            mt={18}
            flexDirection="column"
            alignItems="center"
          >
            <Text fontWeight={600} fontSize={20} color="grey.400">
              Your cart is empty.
            </Text>
            <Link
              mt={6}
              fontWeight="500"
              onClick={() => navigate('/')}
              sx={{ cursor: 'pointer' }}
            >
              HOME
            </Link>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default ReviewOrderMobile;
