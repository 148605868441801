import { CustomerForm, AgentForm } from 'app/hooks/useOrderCheckout';
import uppercaseMask from 'utils/uppercaseMask';
import phoneMask from 'utils/phoneMask';
import { isMobile } from 'utils/isMobile';

export enum FieldTypes {
  TEXT = 'text',
  SELECT = 'select',
}

export interface CustomerFormFields {
  type: FieldTypes;
  label: string;
  name: keyof CustomerForm;
  required?: boolean;
  maxLength?: number;
  mask?: (input: string) => string;
  disabled?: boolean;
}

export interface AgentFormFields {
  label: string;
  name: keyof AgentForm;
  required?: boolean;
  maxLength?: number;
  mask?: (input: string) => string;
  columns?: number;
}

export const customerFormFields: (
  isEdit?: boolean,
) => CustomerFormFields[] = isEdit => [
  {
    type: FieldTypes.TEXT,
    label: 'First Name',
    name: 'firstName',
    required: true,
  },
  {
    type: FieldTypes.TEXT,
    label: 'Last Name',
    name: 'lastName',
    required: true,
  },
  {
    type: FieldTypes.TEXT,
    label: 'Billing Address (Line 1)',
    name: 'billingAddress1',
    required: true,
  },
  {
    type: FieldTypes.TEXT,
    label: 'Billing Address (Line 2)',
    name: 'billingAddress2',
  },
  { type: FieldTypes.TEXT, label: 'City', name: 'city', required: true },
  {
    type: FieldTypes.TEXT,
    label: 'State',
    name: 'state',
    required: true,
    maxLength: 2,
    mask: uppercaseMask,
  },
  {
    type: FieldTypes.SELECT,
    label: 'Country',
    name: 'country',
    required: true,
  },
  {
    type: FieldTypes.TEXT,
    label: 'Zip Code',
    name: 'zipCode',
    required: true,
    maxLength: 9,
  },
  {
    type: FieldTypes.TEXT,
    label: 'Primary Cell Phone',
    name: 'primaryCellPhone',
    required: true,
    mask: phoneMask,
  },
  {
    type: FieldTypes.TEXT,
    label: 'Confirm Primary Cell Phone',
    name: 'confirmPrimaryCellPhone',
    required: true,
    mask: phoneMask,
  },
  {
    type: FieldTypes.TEXT,
    label: 'Secondary Cell Phone',
    name: 'secondaryCellPhone',
    mask: phoneMask,
  },
  {
    type: FieldTypes.TEXT,
    label: 'E-mail Address',
    name: 'emailAddress',
    required: true,
    disabled: isEdit,
  },
  {
    type: FieldTypes.TEXT,
    label: 'Confirm E-mail',
    name: 'confirmEmail',
    required: true,
  },
  {
    type: FieldTypes.TEXT,
    label: 'Secondary E-mail',
    name: 'secondaryEmail',
  },
];

export const agentFormFields: AgentFormFields[] = [
  {
    label: 'First Name',
    name: 'agentFirstName',
    required: true,
    maxLength: 50,
    columns: isMobile ? 12 : 6,
  },
  {
    label: 'Last Name',
    name: 'agentLastName',
    required: true,
    maxLength: 50,
    columns: isMobile ? 12 : 6,
  },
  {
    label: 'Travel Agency',
    name: 'agencyName',
    required: true,
    maxLength: 50,
  },
  {
    label: 'Agent Phone Number',
    name: 'agentPhoneNumber',
    required: true,
    maxLength: 50,
    mask: phoneMask,
  },
  {
    label: 'Agent E-mail',
    name: 'agentEmail',
    required: true,
    maxLength: 50,
  },
  {
    label: 'PayPal Commission Email',
    name: 'payPalCommissionAccount',
    required: true,
    maxLength: 50,
  },
  {
    label: 'IATA / CLIA #',
    name: 'iataClia',
    maxLength: 50,
    required: true,
  },
];
