import { FormContainer } from 'app/hooks/useGlobalForm';
import { EditFormContainer } from 'app/hooks/useEditForm';
import { CreateFormContainer } from 'app/hooks/useCreateForm';
import { GlobalStateContainer } from 'app/hooks/useGlobalState';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Tap from '@tapfiliate/tapfiliate-js';
import ThemeProvider from '@concepta/react-material-ui/dist/styles/ThemeProvider';
import { ClientProvider } from '@concepta/react-data-provider';
import useGetSystemParameter from 'app/hooks/useGetSystemParameter';
import theme from 'app/core/theme';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { isMobile } from 'utils/isMobile';
import AppRouter from 'AppRouter';

const TAP_ID = process.env.REACT_APP_TAP_ID;

function App() {
  useGetSystemParameter();

  Tap.init(TAP_ID);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <ThemeProvider theme={theme}>
        <ClientProvider>
          <GlobalStateContainer.Provider>
            <CreateFormContainer.Provider>
              <EditFormContainer.Provider>
                <FormContainer.Provider>
                  <ToastContainer
                    position={isMobile ? 'bottom-center' : 'top-right'}
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="colored"
                  />
                  <AppRouter />
                </FormContainer.Provider>
              </EditFormContainer.Provider>
            </CreateFormContainer.Provider>
          </GlobalStateContainer.Provider>
        </ClientProvider>
      </ThemeProvider>
    </LocalizationProvider>
  );
}

export default App;
