import { FC, PropsWithChildren } from 'react';
import Box from '@mui/material/Box';
import { OrderQuoteOutputInterface } from 'ks-common';

import ProductCard from 'app/components/ProductCard/ProductCard';
import { EditFormContainer } from 'app/hooks/useEditForm';
import ReviewOrderDesktopDisplay from 'app/screens/ReviewOrder/ReviewOrderDesktop/ReviewOrderDesktopDisplay';
import { isMobile } from 'utils/isMobile';

interface Props {
  quoteData: OrderQuoteOutputInterface;
  isPending: boolean;
  readonly?: boolean;
  hideCostSummary?: boolean;
  errorMessage?: string;
}

const Review: FC<PropsWithChildren<Props>> = ({
  quoteData,
  isPending,
  readonly,
  hideCostSummary,
  errorMessage,
  children,
}) => {
  const { products } = EditFormContainer.useContainer();

  return (
    <Box width="100%">
      {isMobile ? (
        products.map((product, i) => (
          <ProductCard
            key={`${product.product?.ProductID}${i}`}
            product={product}
            index={i}
            isEdit
            readonly={readonly}
          />
        ))
      ) : (
        <ReviewOrderDesktopDisplay
          orderQuoteCalculated={quoteData}
          isPending={isPending}
          isEdit
          readonly={readonly}
          hideCostSummary={hideCostSummary}
          errorMessage={errorMessage}
        >
          {children}
        </ReviewOrderDesktopDisplay>
      )}
    </Box>
  );
};

export default Review;
