import { FC, useState } from 'react';
import { Image } from '@concepta/react-material-ui';
import Box from '@mui/material/Box';
import Drawer from './Drawer';
import { FormContainer } from 'app/hooks/useGlobalForm';
import { useNavigate } from '@concepta/react-router';
import logo from 'assets/images/kgd-strollers-logo.jpg';
import ShoppingCart from '@mui/icons-material/ShoppingCart';
import AppBar from '@mui/material/AppBar';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import Toolbar from '@mui/material/Toolbar';

interface Props {
  backButton?: boolean;
  handleBack?: () => void;
  isEdit?: boolean;
  hideLastButton?: boolean;
}

const HeaderMobile: FC<Props> = ({
  backButton,
  handleBack,
  isEdit,
  hideLastButton,
}) => {
  const navigate = useNavigate();
  const { products } = FormContainer.useContainer();
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(prevState => !prevState);
  };

  const handleLeftIconClick = () => {
    if (backButton) {
      if (handleBack) return handleBack();
      return navigate(-1);
    }
    handleDrawerToggle();
  };

  const handleCart = () => {
    if (isEdit) return;

    if (products[0]?.step === 1) {
      navigate('/accessories');
    }
    if (products[0]?.step === 2) {
      navigate('/review');
    }
  };

  const goToHome = () => {
    navigate('/');
  };

  return (
    <Box
      pt={2}
      sx={{
        position: 'sticky',
        top: 0,
        left: 0,
        right: 0,
        backgroundColor: 'common.white',
        zIndex: 10,
      }}
    >
      <AppBar
        position="fixed"
        component="nav"
        sx={{
          backgroundColor: 'common.white',
          boxShadow: 'none',
          display: 'contents',
        }}
      >
        <Toolbar sx={{ backgroundColor: 'common.white' }}>
          <Box
            display="flex"
            width="100%"
            alignItems="center"
            justifyContent="space-between"
          >
            <IconButton
              aria-label="open drawer"
              edge="start"
              onClick={handleLeftIconClick}
              sx={{ color: 'grey.600' }}
            >
              {backButton ? (
                <ChevronLeft sx={{ fontSize: '34px', marginLeft: '-10px' }} />
              ) : (
                <MenuIcon />
              )}
            </IconButton>

            <Box onClick={goToHome}>
              <Image
                src={logo}
                alt="Kingdom Strollers logo"
                width="auto"
                height="100%"
                maxHeight="48px"
              />
            </Box>
            <IconButton
              aria-label="open cart"
              edge="end"
              onClick={handleCart}
              sx={
                isEdit
                  ? {
                      opacity: 0,
                    }
                  : {
                      color: 'common.white',
                      backgroundColor: 'secondary.main',
                      padding: 0.8,
                      mr: 0.5,
                    }
              }
            >
              <ShoppingCart sx={{ fontSize: 20 }} />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>

      <Drawer
        mobileOpen={mobileOpen}
        handleDrawerToggle={handleDrawerToggle}
        isEdit={isEdit}
        hideLastButton={hideLastButton}
      />
      <Divider />
    </Box>
  );
};

export default HeaderMobile;
