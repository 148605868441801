import { FC, Fragment, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import Divider from '@mui/material/Divider';
import { Text } from '@concepta/react-material-ui';
import { Form, LocationType } from 'app/hooks/useGlobalForm';
import FormLabel from 'app/components/FormLabel';
import EditIcon from '@mui/icons-material/EditOutlined';
import { FormContainer } from 'app/hooks/useGlobalForm';
import Accessories from 'app/screens/Accessories';
import { isMobile } from 'utils/isMobile';
import isToday from 'date-fns/isToday';
import { DateObject } from 'react-multi-date-picker';
import formatDate from 'utils/formatDate';

interface Props {
  product: Partial<Form>;
  index: number;
  readonly?: boolean;
  isEdit?: boolean;
}

interface LeftRight {
  left: string;
  right?: string;
  error?: boolean;
}

const Location: FC<Props> = ({ product, index, readonly, isEdit }) => {
  const { setCurrentProductIndex } = FormContainer.useContainer();
  const [accessoriesDialogOpen, setAccessoriesDialogOpen] = useState(false);

  const navigate = useNavigate();

  const handleEditClick = () => {
    if (readonly) return;

    setCurrentProductIndex(index);

    if (!isMobile || isEdit) {
      return openAccessoriesDialog();
    }

    navigate('/accessories', {
      state: { isReview: true },
    });
  };

  const deliveryDate = product.deliveryDate;
  const returnDate = product.returnDate;

  const deliveryLocation = product.deliveryLocation;
  const returnLocation = product.returnLocation;

  const deliveryDateIsToday =
    !!deliveryDate &&
    isToday(
      new DateObject({
        date: deliveryDate,
        format: 'YYYY-MM-DD',
      }).toDate(),
    );

  const openAccessoriesDialog = () => {
    setAccessoriesDialogOpen(true);
  };
  const closeAccessoriesDialog = () => {
    setAccessoriesDialogOpen(false);
  };

  const deliveryLocationName = (
    location?: Form['returnLocation'] | Form['deliveryLocation'],
  ) => {
    const locationType = location?.location;

    if (locationType === LocationType.Airport) {
      return 'Orlando International Airport - MCO';
    }

    if (locationType === LocationType.VacationHome) {
      return location?.vacationHome?.streetAddress || 'Vacation Home Rental';
    }

    if (locationType === LocationType.Resort) {
      return location?.resort?.ResortName;
    }

    return '';
  };

  const renderLocation = (
    location?: Form['returnLocation'] | Form['deliveryLocation'],
    date?: Form['deliveryDate'] | Form['returnDate'],
    isDelivery?: boolean,
  ) => {
    const rows: LeftRight[] = [
      {
        left: 'Location:',
        right: deliveryLocationName(location),
      },
      {
        left: 'Date:',
        right: formatDate(date),
        error: isDelivery && deliveryDateIsToday,
      },
      {
        left: 'Time:',
        right: location?.time || '',
      },
    ];

    return rows.map(info => leftRight(info));
  };

  const leftRight = (info: LeftRight) => (
    <Box
      key={info.left + info.right}
      display="flex"
      flex={1}
      width="100%"
      sx={{ mt: 1, overflow: 'hidden' }}
    >
      <Box display="flex">
        <Text
          fontSize={isMobile ? 14 : 16}
          fontWeight={400}
          color="primary.darker"
        >
          {info.left}
        </Text>
      </Box>
      <Box
        display="flex"
        ml={isMobile ? 2 : 4}
        flex={1}
        justifyContent="flex-end"
      >
        <Text
          fontSize={isMobile ? 14 : 16}
          fontWeight={400}
          align="right"
          color={info.error ? 'error.main' : 'inherit'}
        >
          {info.right}
        </Text>
      </Box>
    </Box>
  );

  return (
    <Fragment>
      <Box
        mt={4}
        px={2}
        onClick={handleEditClick}
        sx={{
          cursor: readonly ? 'auto' : 'pointer',
        }}
      >
        <Box display="flex" alignItems="center">
          <FormLabel mb={0}>Delivery Details:</FormLabel>{' '}
          {!readonly && (
            <EditIcon sx={{ fontSize: '18px', color: 'grey.900', ml: 1 }} />
          )}
        </Box>

        {renderLocation(deliveryLocation, deliveryDate, true)}
      </Box>

      <Divider sx={{ mt: 2, backgroundColor: 'primary.darker' }} />

      <Box
        mt={2}
        px={2}
        onClick={handleEditClick}
        sx={{
          cursor: readonly ? 'auto' : 'pointer',
        }}
      >
        <Box display="flex" alignItems="center">
          <FormLabel mb={0}>Return Details:</FormLabel>{' '}
          {!readonly && (
            <EditIcon sx={{ fontSize: '18px', color: 'grey.900', ml: 1 }} />
          )}
        </Box>

        {renderLocation(returnLocation, returnDate)}
      </Box>

      <Dialog
        open={accessoriesDialogOpen}
        scroll="body"
        fullWidth
        maxWidth="lg"
        fullScreen={isMobile && isEdit}
      >
        {accessoriesDialogOpen && (
          <Accessories
            isReview
            onConfirm={closeAccessoriesDialog}
            onCancel={closeAccessoriesDialog}
            isModal
            isEdit={isEdit}
          />
        )}
      </Dialog>
    </Fragment>
  );
};

export default Location;
