import { FC } from 'react';
import { Helmet } from 'react-helmet';
import AcessoriesDesktop from './AcessoriesDesktop/AcessoriesDesktop';
import AcessoriesMobile from './AcessoriesMobile/AcessoriesMobile';
import { AccessoriesScreenContainer } from 'app/hooks/useAccessoriesScreenState';
import { AccessoriesProps } from './Components/AccessoriesForm';
import { isMobile } from 'utils/isMobile';
import useScrollTop from 'app/hooks/useScrollTop';

const ENV = process.env.REACT_APP_ENV;

const Accessories: FC<AccessoriesProps> = props => {
  useScrollTop(true);

  return (
    <>
      <Helmet>
        {ENV === 'PRODUCTION' && (
          <script>{`<!-- Google Tag Manager -->
(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-T9PWBRCX');
<!-- End Google Tag Manager —>`}</script>
        )}
      </Helmet>
      {ENV === 'PRODUCTION' && (
        <iframe
          src="https://www.googletagmanager.com/ns.html?id=GTM-T9PWBRCX"
          height="0"
          width="0"
          style={{ display: 'none', visibility: 'hidden' }}
        ></iframe>
      )}
      <AccessoriesScreenContainer.Provider>
        {isMobile ? (
          <AcessoriesMobile
            onConfirm={props?.onConfirm}
            onCancel={props?.onCancel}
            isEdit={props.isEdit}
          />
        ) : (
          <AcessoriesDesktop {...props} />
        )}
      </AccessoriesScreenContainer.Provider>
    </>
  );
};

export default Accessories;
