import { FC, useEffect, useMemo } from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { RadioGroup, Text } from '@concepta/react-material-ui';
import { RadioOptions } from '@concepta/react-material-ui/dist/components/RadioGroup';
import {
  FormContainer,
  LocationType,
  LocationMoment,
  AccessoriesFormErrors,
} from 'app/hooks/useGlobalForm';
import useGetResortData from 'app/hooks/useGetResortData';
import FormLabel from 'app/components/FormLabel';
import DatePicker from 'app/components/DatePicker';
import LocationResort from './LocationResort';
import LocationVacationHome from './LocationVacationHome';
import LocationTime from './LocationTime';
import LocationSameAsCheckin from './LocationSameAsCheckin';
import useLocalStorageState from 'use-local-storage-state';
import { SystemParameterInterface } from 'ks-common';
import ErrorText from 'app/components/ErrorText';
import {
  AccessoriesScreenContainer,
  Instructions,
} from 'app/hooks/useAccessoriesScreenState';
import { isMobile } from 'utils/isMobile';
import isToday from 'date-fns/isToday';
import { DateObject } from 'react-multi-date-picker';
import LocationWarnings from './LocationWarnings';
import BlueWrapper from 'app/components/BlueWrapper';

// TODO get constants from ks-common (Got help from Marshall. This is not working now and we don't know why)
const DELIVERY_TIME_PREMIUM_FEE = '06:00 AM';

interface Props {
  id: LocationMoment;
  formErrors?: Partial<AccessoriesFormErrors>;
  zipError?: string;
  dateError?: string;
}

const Location: FC<Props> = ({ id, formErrors, zipError, dateError }) => {
  const [systemParams] =
    useLocalStorageState<SystemParameterInterface[]>('systemParams');
  const { currentProduct, updateCurrentProduct } = FormContainer.useContainer();
  const {
    deliveryInstructions,
    returnInstructions,
    setDeliveryInstructions,
    setReturnInstructions,
  } = AccessoriesScreenContainer.useContainer();

  const isReturn = id === 'returnLocation';
  const location = currentProduct[id]?.location;
  const locationTime = currentProduct[id]?.time;

  const isResort = location === LocationType.Resort;
  const isVacationHome = location === LocationType.VacationHome;
  const isAirport = location === LocationType.Airport;

  const formResort = currentProduct[id]?.resort;
  const formResortId = formResort?.ResortId;

  const resortId = useMemo(() => {
    if (isAirport) {
      return 8415;
    }
    if (isVacationHome) {
      return 243;
    }

    return formResortId;
  }, [location, formResortId]);

  const {
    data: resortData,
    execute,
    isPending,
    error,
  } = useGetResortData(resortId);

  const locationError = formErrors?.[id];

  useEffect(() => {
    if (resortId) {
      execute();
    }
  }, [location, resortId]);

  const onLocationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    updateCurrentProduct({
      [id]: {
        location: event.target.value,
      },
    });
  };

  const options = [
    {
      label: 'Resort',
      value: 'resort',
    },
    {
      label: 'Vacation Home',
      value: 'vacationHome',
    },
    {
      label: 'Airport (MCO)',
      value: 'airport',
      disabled:
        currentProduct?.product?.TypeProduct &&
        [3, 4, 5].includes(currentProduct.product.TypeProduct),
    },
  ];

  useEffect(() => {
    if (!location) {
      updateCurrentProduct({
        [id]: {
          location: options[0].value,
        },
      });
    }
    if (
      location &&
      location === 'airport' &&
      currentProduct?.product?.TypeProduct &&
      [3, 4, 5].includes(currentProduct.product.TypeProduct)
    ) {
      updateCurrentProduct({
        [id]: {
          location: options[0].value,
        },
      });
    }
  }, []);

  const disneyInstructionMessages = {
    deliveryLocation: systemParams?.find(
      param => param.ParamName === 'Disney_Delivery_Message',
    )?.ParamValue,
    returnLocation: systemParams?.find(
      param => param.ParamName === 'Disney_Pickup_Message',
    )?.ParamValue,
  };

  const isDisney = Number(formResort?.ResortType) === 1;

  const disneyInstructions = isDisney && disneyInstructionMessages[id];

  const resortIntructionsMessages = {
    deliveryLocation: resortData?.WarningDeliveryText,
    returnLocation: resortData?.WarningPickupText,
  };

  const resortIntructions = resortIntructionsMessages[id];

  const timeError = {
    deliveryLocation: formErrors?.deliveryTime,
    returnLocation: formErrors?.returnTime,
  };

  const title = `Select ${
    id === 'deliveryLocation' ? 'Delivery' : 'Return'
  } Location and Time`;

  const resortError = {
    deliveryLocation: formErrors?.deliveryLocationResort,
    returnLocation: formErrors?.returnLocationResort,
  };

  const vacationHomeError = {
    deliveryLocation: formErrors?.deliveryVacationHomeFields,
    returnLocation: formErrors?.returnVacationHomeFields,
  };

  const disabledDates = useMemo(
    () =>
      systemParams?.find(param => param.ParamName === 'Blocked_Dates')
        ?.ParamValue,
    [systemParams],
  );

  const dateValue = isReturn
    ? currentProduct?.returnDate
    : currentProduct?.deliveryDate;

  const dateOnChange = (values: (string | undefined | null)[] | null) => {
    if (values) {
      updateCurrentProduct({ deliveryDate: values[0], returnDate: values[1] });
    }
  };

  useEffect(() => {
    const instructions: Instructions = {};

    if (isResort && disneyInstructions) {
      instructions.disneyInstructions = disneyInstructions;
    }

    if (resortId && (isResort || isAirport) && resortIntructions) {
      instructions.resortInstructions = resortIntructions;
    }

    id === 'deliveryLocation'
      ? setDeliveryInstructions(instructions)
      : setReturnInstructions(instructions);
  }, [resortId, resortIntructions]);

  const locationFeeTime =
    id === 'deliveryLocation' && isResort && DELIVERY_TIME_PREMIUM_FEE;

  const deliveryDateIsToday =
    !isReturn &&
    !!currentProduct?.deliveryDate &&
    isToday(
      new DateObject({
        date: currentProduct.deliveryDate,
        format: 'YYYY-MM-DD',
      }).toDate(),
    );

  const instructions =
    id === 'deliveryLocation' ? deliveryInstructions : returnInstructions;

  // const hasInstructions = !!Object.values(instructions).filter(i => !!i).length;
  const hasInstructions = !!Object.values(instructions).length;

  return (
    <Box>
      <FormLabel subLabel mt={isReturn ? 4 : 1} mb={1}>
        {title}
      </FormLabel>

      {isReturn && <LocationSameAsCheckin />}

      <RadioGroup
        options={options as RadioOptions[]}
        onChange={onLocationChange}
        value={location}
        row={!isMobile}
      />
      {locationError && <ErrorText>{locationError}</ErrorText>}

      {isResort && (
        <LocationResort id={id} error={resortError[id]} location={location} />
      )}

      {isVacationHome && (
        <LocationVacationHome
          id={id}
          error={zipError}
          fieldsErrors={vacationHomeError[id]}
        />
      )}

      {isAirport && (
        <>
          <Text
            mt={1}
            fontFamily="Roboto, Helvetica, Arial, sans-serif"
            fontSize="16px"
            fontWeight={400}
            color="grey.700"
          >
            Orlando International Airport - MCO
          </Text>
          <Divider sx={{ background: 'grey.200', mt: '4px' }} />
        </>
      )}

      {location && (
        <Box
          display="flex"
          alignItems="flex-end"
          maxWidth={!isMobile ? '380px' : '100%'}
        >
          {!isMobile && (
            <Box flex={1}>
              <DatePicker
                label="Date"
                value={dateValue}
                onChange={dateOnChange}
                disabledDates={disabledDates}
                error={deliveryDateIsToday}
                defaultColor
                variant="standard"
                type={id === 'returnLocation' ? 'return' : 'delivery'}
              />
            </Box>
          )}
          <Box flex={1} {...(!isMobile && { ml: 4 })}>
            <LocationTime
              id={id}
              disabled={isPending || !!error || !resortData}
              resort={resortData}
              error={timeError[id]}
            />
          </Box>
        </Box>
      )}

      {!isMobile && deliveryDateIsToday && (
        <ErrorText>Please review and enter a valid delivery date.</ErrorText>
      )}

      {!isMobile && timeError[id] && <ErrorText>{timeError[id]}</ErrorText>}

      {isResort &&
        locationTime === locationFeeTime &&
        formResort?.ResortType !== 1 && (
          <ErrorText>
            A $10.00 premium will be applied for 6am deliveries.
          </ErrorText>
        )}

      {dateError && <ErrorText>{dateError}</ErrorText>}

      {isMobile && hasInstructions && (
        <BlueWrapper boxProps={{ mt: 4 }}>
          <LocationWarnings id={id} />
        </BlueWrapper>
      )}
    </Box>
  );
};

export default Location;
