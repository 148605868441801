import { FC, useCallback, useMemo } from 'react';
import { useNavigate } from '@concepta/react-router';
import useMediaQuery from '@mui/material/useMediaQuery';
import Container from '@mui/material/Container';
import { FormContainer } from 'app/hooks/useGlobalForm';
import HeaderButton from './HeaderButton';
import { Image } from '@concepta/react-material-ui';
import Box from '@mui/material/Box';
import ShoppingCart from '@mui/icons-material/ShoppingCart';
import logo from 'assets/images/kgd-strollers-logo-desktop.jpg';
import { SystemParameterInterface } from 'ks-common';
import useLocalStorageState from 'use-local-storage-state';

const EDIT_ORDER_DEFAULT_PATH = process.env
  .REACT_APP_EDIT_ORDER_DEFAULT_PATH as string;

interface Props {
  isEdit?: boolean;
  hideLastButton?: boolean;
}

const HeaderDesktop: FC<Props> = ({ isEdit, hideLastButton }) => {
  const navigate = useNavigate();
  const [systemParams] =
    useLocalStorageState<SystemParameterInterface[]>('systemParams');
  const { products, logOut } = FormContainer.useContainer();
  const isSmallScreen = useMediaQuery('(max-width:1250px)');

  const currentDomain = window.location.origin;
  const editOrderSystemParameterUrl = systemParams?.find(
    param => param.ParamName === 'Edit_Order_URL',
  )?.ParamValue;

  const goTo = (path: string) => () => {
    navigate(path);
  };

  const handleEditOrderNavigate = () => {
    if (!editOrderSystemParameterUrl) {
      navigate(EDIT_ORDER_DEFAULT_PATH);
      return;
    }

    const targetUrl = new URL(editOrderSystemParameterUrl, currentDomain);

    if (targetUrl.origin !== currentDomain) {
      window.location.replace(editOrderSystemParameterUrl);
      return;
    }

    navigate(EDIT_ORDER_DEFAULT_PATH);
  };

  const logOutFlow = () => {
    logOut();
    navigate(EDIT_ORDER_DEFAULT_PATH);
  };

  const options = useMemo(() => {
    const optArr = [
      { text: 'Home', onClick: goTo('/') },
      { text: 'About Us', onClick: goTo('/about-us') },
      { text: 'Testimonials', onClick: goTo('/testimonials') },
      { text: 'Contact Us', onClick: goTo('/contact-us') },
      { text: 'FAQs', onClick: goTo('/faqs') },
      { text: 'Rental Agreements', onClick: goTo('/rental-agreement') },
    ];

    if (!hideLastButton) {
      optArr.push(
        isEdit
          ? { text: 'Logout', onClick: logOutFlow }
          : {
              text: 'Edit Order',
              onClick: handleEditOrderNavigate,
            },
      );
    }

    return optArr;
  }, [isEdit, hideLastButton]);

  const renderOptions = useCallback(
    () =>
      options.map(option => (
        <HeaderButton onClick={option.onClick} key={option.text}>
          {option.text}
        </HeaderButton>
      )),
    [],
  );

  const handleCart = () => {
    if (products[0]?.step === 1) {
      navigate('/accessories');
    }
    if (products[0]?.step === 2) {
      navigate('/review');
    }
  };

  return (
    <Box
      position="relative"
      sx={{
        pb: 2,
        zIndex: 5,
        backgroundColor: 'common.white',
      }}
    >
      <Container maxWidth="xl">
        <Box
          display="flex"
          py={2}
          px={isSmallScreen ? 2 : 5}
          alignItems="flex-end"
          overflow="hidden"
        >
          <Image src={logo} alt="Kingdom Strollers logo" sx={{ width: 211 }} />
          <Box flex={1} display="flex" justifyContent="center" flexWrap="wrap">
            {renderOptions()}
          </Box>
          {!isEdit && (
            <HeaderButton
              onClick={handleCart}
              sx={{
                backgroundColor: 'secondary.main',
                color: 'common.white',
                borderRadius: 1,
                px: 1.5,
              }}
            >
              <ShoppingCart sx={{ fontSize: 24, mr: 2 }} /> <Box>View Cart</Box>
            </HeaderButton>
          )}
        </Box>
      </Container>
      <Box
        sx={{
          height: '1px',
          backgroundColor: 'grey.300',
        }}
        position="absolute"
        left="0"
        right="0"
        bottom="16px"
      />
    </Box>
  );
};

export default HeaderDesktop;
