import { FC } from 'react';
import { Box } from '@mui/material';
import { Text } from '@concepta/react-material-ui';
import { DateObject } from 'react-multi-date-picker';

import { EditFormContainer } from 'app/hooks/useEditForm';
import LeftRightInfo from 'app/components/LeftRightInfo';
import { isMobile } from 'utils/isMobile';
import ResendConfirmationEmail from './ResendConfirmationEmail';

const OrderInfoHeader: FC = () => {
  const { originalOrder } = EditFormContainer.useContainer();

  const leftRightInfo = [
    { left: 'Order Number:', right: originalOrder?.OrderId },
    {
      left: 'Order Date:',
      right: originalOrder?.OrderDate
        ? new DateObject({
            date: originalOrder.OrderDate,
            format: 'YYYY-MM-DD',
          }).format('MM/DD/YYYY')
        : '',
    },
  ];

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexDirection: isMobile ? 'column' : 'row',
        pb: isMobile ? 1 : 2,
        borderBottom: '1px solid',
        mb: 1,
      }}
    >
      <Text
        fontSize={isMobile ? 24 : 32}
        fontWeight={700}
        color="primary.darker"
      >
        Order Information
      </Text>

      {!isMobile && (
        <Box
          sx={{
            px: {
              xs: 1,
              sm: 2,
              md: 4,
              lg: 8,
            },
            py: 1,
            flex: 1,
            display: 'flex',
            justifyContent: 'flex-start',
          }}
        >
          <ResendConfirmationEmail />
        </Box>
      )}

      <Box
        sx={{
          width: isMobile ? '100%' : '290px',
          px: isMobile ? 4 : 0,
        }}
      >
        {leftRightInfo.map(info => (
          <LeftRightInfo
            key={info.left}
            sx={isMobile ? { mb: 0.5 } : {}}
            left={
              <Text
                fontSize={isMobile ? 16 : 19}
                fontWeight={500}
                color="text.primary"
              >
                {info.left}
              </Text>
            }
            right={
              <Text
                fontSize={isMobile ? 15 : 19}
                fontWeight={800}
                color="text.primary"
              >
                {info.right}
              </Text>
            }
          />
        ))}
      </Box>
    </Box>
  );
};

export default OrderInfoHeader;
