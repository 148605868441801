import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { OrderEditLoadInterface } from 'ks-common';
import { useNavigate } from '@concepta/react-router';
import { Box, CircularProgress } from '@mui/material';
import { EditFormContainer } from 'app/hooks/useEditForm';
import { Form } from 'app/hooks/useGlobalForm';
import useGetOrder from 'app/hooks/useGetOrder';
import Header from 'app/components/Header';
import Footer from 'app/components/Footer';
import useGetAllProducts from 'app/hooks/useGetAllProducts';
import useGetAllResorts from 'app/hooks/useGetAllResorts';

import EditOrder from './EditOrder';

const EDIT_ORDER_DEFAULT_PATH = process.env
  .REACT_APP_EDIT_ORDER_DEFAULT_PATH as string;

const EditOrderLoader = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { orderId } = params;

  const { allProducts } = useGetAllProducts();
  useGetAllResorts();

  const { setOriginalOrder, products } = EditFormContainer.useContainer();

  const hasProducts = products.some(
    (product: Partial<Form>) => product.step && product.step >= 1,
  );

  const onOrderSuccess = (data: OrderEditLoadInterface) => {
    setOriginalOrder(data);
  };

  const { execute: getOrder } = useGetOrder({
    id: Number(orderId),
    onSuccess: onOrderSuccess,
    onError: () => {
      // We don't need to check the `Edit_Order_URL` here because if the user
      // is in this page then the URL is local.
      navigate(EDIT_ORDER_DEFAULT_PATH);
    },
  });

  const showEditOrder = hasProducts && allProducts;

  useEffect(() => {
    if (!hasProducts && orderId && allProducts) {
      getOrder();
    }
  }, [hasProducts, allProducts]);

  return (
    <Box minHeight="100vh">
      <Header isEdit />
      <Box display="flex" flex={1} sx={{ py: 4 }}>
        {!showEditOrder && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flex: 1,
            }}
          >
            <Box>
              <CircularProgress />
            </Box>
          </Box>
        )}
        {showEditOrder && <EditOrder />}
      </Box>
      <Footer />
    </Box>
  );
};

export default EditOrderLoader;
