import { FC } from 'react';
import { Box } from '@mui/material';
import { Text } from '@concepta/react-material-ui';
import { DateObject } from 'react-multi-date-picker';

import { EditFormContainer } from 'app/hooks/useEditForm';
import { LeftRightProps } from 'app/components/LeftRightInfo';
import { isMobile } from 'utils/isMobile';

const OrderInfoHeader: FC = () => {
  const { originalOrder } = EditFormContainer.useContainer();

  const topInfo: LeftRightProps[] = [
    { left: 'Order Number:', right: originalOrder?.OrderId },
    {
      left: 'Order Date:',
      right: originalOrder?.OrderDate
        ? new DateObject({
            date: originalOrder.OrderDate,
            format: 'YYYY-MM-DD',
          }).format('MM/DD/YYYY')
        : '',
    },
  ];

  const bottomInfo: LeftRightProps[] = [
    { left: 'Full Name:', right: originalOrder?.Customer?.FullName },
    {
      left: 'Email:',
      right: originalOrder?.Customer?.Email,
    },
  ];

  const renderRow = (info: LeftRightProps, spaceBetween?: boolean) => (
    <Box
      key={info.left as string}
      sx={{
        display: 'flex',
        justifyContent: spaceBetween ? 'space-between' : 'flex-start',
        overflow: 'hidden',
        ...(isMobile && { mb: 0.5 }),
      }}
    >
      <Box
        sx={{
          width: isMobile ? '130px' : '160px',
          minWidth: isMobile ? '130px' : '160px',
        }}
      >
        <Text
          fontSize={isMobile ? 15 : 19}
          fontWeight={500}
          color="text.primary"
        >
          {info.left}
        </Text>
      </Box>
      <Box overflow="hidden">
        <Text
          fontSize={isMobile ? 15 : 19}
          fontWeight={800}
          color="text.primary"
          sx={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {info.right}
        </Text>
      </Box>
    </Box>
  );

  return (
    <Box
      sx={{
        width: '100%',
      }}
    >
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          flexDirection: isMobile ? 'column' : 'row',
          pb: isMobile ? 1 : 2,
          borderBottom: '1px solid',
          mb: 1,
        }}
      >
        <Text
          fontSize={isMobile ? 24 : 32}
          fontWeight={700}
          color="primary.darker"
        >
          Order Information
        </Text>

        <Box
          sx={{
            width: isMobile ? '100%' : '290px',
            px: isMobile ? 4 : 0,
          }}
        >
          {topInfo.map(values => renderRow(values, !isMobile))}
          {isMobile && bottomInfo.map(values => renderRow(values))}
        </Box>
      </Box>
      <Box
        sx={{
          width: '100%',
        }}
      >
        {!isMobile && bottomInfo.map(values => renderRow(values))}
      </Box>
    </Box>
  );
};

export default OrderInfoHeader;
