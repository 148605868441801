import { FC } from 'react';
import { Text } from '@concepta/react-material-ui';
import Box from '@mui/material/Box';
import BlueCard from 'app/components/BlueCard';
import CircularProgress from '@mui/material/CircularProgress';
import priceMask from 'utils/priceMask';
import { isMobile } from 'utils/isMobile';

interface Props {
  total: number;
  isPending: boolean;
}

const TEXT_SIZE = isMobile ? 13 : 18;

const EditOrderCostSummary: FC<Props> = ({ total, isPending }) => {
  return (
    <BlueCard
      sx={{
        py: 2,
      }}
    >
      <Box>
        {isPending && (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            my={4}
          >
            <CircularProgress />
          </Box>
        )}

        {!isPending && (
          <Box display="flex" justifyContent="center" px={4}>
            <Box
              display="flex"
              flexDirection="column"
              flex={1}
              height="fit-content"
            >
              <Box display="flex">
                <Text
                  fontSize={TEXT_SIZE}
                  fontWeight={700}
                  color="primary.darker"
                >
                  Balance Due:
                </Text>
                <Text
                  fontSize={TEXT_SIZE}
                  fontWeight={700}
                  color="primary.darker"
                  sx={{ ml: 'auto' }}
                >
                  {priceMask(total * 100)}
                </Text>
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    </BlueCard>
  );
};

export default EditOrderCostSummary;
