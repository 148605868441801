import { FC, useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import { Text } from '@concepta/react-material-ui';
import TextField from 'app/components/TextField';
import useDebounce from 'app/hooks/useDebounce';

export interface OnChangeTextField {
  (input: { name: string; value: string }): void;
}

interface Props {
  label: string;
  name: string;
  value?: string;
  onChange: OnChangeTextField;
  maxLength?: number;
  required?: boolean;
  mask?: (input: string) => string;
  error?: string;
  disabled?: boolean;
  columns?: number;
}

const CheckoutTextField: FC<Props> = ({
  label,
  name,
  value,
  onChange,
  maxLength = 50,
  required,
  mask,
  error,
  disabled,
  columns = 12,
}) => {
  const [innerValue, setInnerValue] = useState<string | undefined>(value);
  const debouncedValue = useDebounce<string | undefined>(innerValue, 300);

  useEffect(() => {
    typeof innerValue === 'string' && onChange({ name, value: innerValue });
  }, [debouncedValue]);

  useEffect(() => {
    if (value !== innerValue) {
      setInnerValue(value);
    }
  }, [value]);

  const innerOnChange: React.ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = event => {
    const value = event.target.value;
    if (value && mask) {
      return setInnerValue(mask(value));
    }

    setInnerValue(value);
  };

  return (
    <Grid item xs={columns}>
      <TextField
        label={
          <>
            {label}{' '}
            {required && (
              <Text fontSize={12} display="inline">
                (required)
              </Text>
            )}
          </>
        }
        variant="standard"
        fullWidth
        color="info"
        name={name}
        value={innerValue}
        onChange={innerOnChange}
        error={!!error}
        helperText={error}
        inputProps={{ ...(maxLength && { maxLength: maxLength }) }}
        InputLabelProps={{ shrink: !!innerValue }}
        disabled={disabled}
        sx={{ mb: 0 }}
      />
    </Grid>
  );
};

export default CheckoutTextField;
