import useDataProvider, { useQuery } from '@concepta/react-data-provider';
import { toast } from 'react-toastify';
import { addBreadcrumb, captureException } from '@sentry/react';

interface LoginForm {
  orderId: string;
  email: string;
}

interface Props {
  form: LoginForm;
  onSuccess: () => void;
  onError: () => void;
}

const useLogin = ({ form, onSuccess, onError }: Props) => {
  const { post } = useDataProvider();

  const login = () => {
    return post({
      uri: 'auth/order',
      body: {
        orderId: Number(form.orderId),
        email: form.email,
      },
    });
  };

  const { data, execute, isPending, error } = useQuery(login, false, {
    onError: errorResponse => {
      toast.error('Order number or email is not valid');

      captureException({
        category: 'Login',
        message: 'Login error',
        data: form,
        error: errorResponse,
      });

      onError?.();
    },
    onSuccess: data => {
      localStorage.setItem('access_token', data.accessToken);
      localStorage.setItem('refresh_token', data.refreshToken);
      addBreadcrumb({
        category: 'Login',
        message: 'Login success',
        data: form,
      });

      onSuccess();
    },
  });

  return { data, execute, isPending, error };
};

export default useLogin;
