import { FC, ChangeEvent } from 'react';
import { Box, Grid } from '@mui/material';

import { EditFormContainer, CustomerForm } from 'app/hooks/useEditForm';
import CheckoutTextField, {
  OnChangeTextField,
} from 'app/screens/Checkout/Components/CheckoutTextField';
import TextField from 'app/components/TextField';
import { isMobile } from 'utils/isMobile';

export type FormErrors = Partial<Record<keyof CustomerForm, string>>;

interface Field {
  label: string;
  name: keyof CustomerForm;
  disabled?: boolean;
  mask?: (input: string) => string;
}
interface Props {
  errors?: FormErrors;
  readonly?: boolean;
}

const Form: FC<Props> = props => {
  const { errors, readonly } = props;

  const { customerInfo, setCustomerInfo } = EditFormContainer.useContainer();

  const leftFields: Field[] = [
    { label: 'First Name', name: 'firstName', disabled: true },
    { label: 'Last Name', name: 'lastName', disabled: true },
    { label: 'Primary Cell Phone', name: 'primaryCellPhone' },
    {
      label: 'Secondary Cell Phone',
      name: 'secondaryCellPhone',
    },
  ];

  const rightFields: Field[] = [
    { label: 'E-mail Address', name: 'emailAddress', disabled: true },
    { label: 'Secondary E-mail', name: 'secondaryEmail' },
  ];

  const onChange: OnChangeTextField = ({ name, value }) => {
    setCustomerInfo({ ...customerInfo, [name]: value });
  };

  const onDesktopChange = (
    evt: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    mask?: Field['mask'],
  ) => {
    const { name, value } = evt.target;

    setCustomerInfo({ ...customerInfo, [name]: mask ? mask(value) : value });
  };

  return (
    <Box
      sx={{
        mt: 1,
        width: '100%',
      }}
    >
      {isMobile && (
        <Grid container spacing={2} rowSpacing={1}>
          {[...leftFields, ...rightFields].map(field => (
            <CheckoutTextField
              key={field.name}
              label={field.label}
              name={field.name}
              value={customerInfo?.[field.name] as string}
              onChange={onChange}
              error={errors?.[field.name]}
              disabled={field.disabled || readonly}
              mask={field.mask}
            />
          ))}
        </Grid>
      )}

      {!isMobile && (
        <Box sx={{ display: 'flex', width: '100%' }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              flex: 5,
              pl: 4,
              pr: 6,
            }}
          >
            <Grid container spacing={4} rowSpacing={1}>
              {leftFields.map(field => (
                <Grid item key={field.name} xs={6}>
                  <TextField
                    fullWidth
                    key={field.name}
                    label={field.label}
                    name={field.name}
                    value={customerInfo?.[field.name]}
                    onChange={evt => onDesktopChange(evt, field.mask)}
                    helperText={errors?.[field.name]}
                    error={!!errors?.[field.name]}
                    disabled={field.disabled || readonly}
                    sx={{ mb: 1 }}
                  />
                </Grid>
              ))}
            </Grid>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              flex: 4,
              pr: 4,
              pl: 6,
            }}
          >
            <Grid container spacing={4} rowSpacing={1}>
              {rightFields.map(field => (
                <Grid item key={field.name} xs={12}>
                  <TextField
                    fullWidth
                    key={field.name}
                    label={field.label}
                    name={field.name}
                    value={customerInfo?.[field.name]}
                    onChange={evt => onDesktopChange(evt, field.mask)}
                    helperText={errors?.[field.name]}
                    error={!!errors?.[field.name]}
                    disabled={field.disabled || readonly}
                    sx={{ mb: 1 }}
                  />
                </Grid>
              ))}
            </Grid>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default Form;
