import { FC, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Text } from '@concepta/react-material-ui';
import { useTheme } from '@mui/material';
import { Box, Button } from '@mui/material';
import { useNavigate } from '@concepta/react-router';
import { OrderEditLoadInterface } from 'ks-common';

import { EditFormContainer } from 'app/hooks/useEditForm';
import TextField from 'app/components/TextField';
import useLogin from 'app/hooks/useLogin';
import useGetOrder from 'app/hooks/useGetOrder';
import { isMobile } from 'utils/isMobile';
import emailValidation from 'utils/emailValidation';
import numberMask from 'utils/numberMask';
import generateUuid from 'utils/generateUuid';

interface LoginForm {
  orderId: string;
  email: string;
}

interface Field {
  label: string;
  name: keyof LoginForm;
  mask?: (input: string) => string;
}

const Form: FC = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const location = useLocation();
  const { setOriginalOrder, setOrderQuoteId } =
    EditFormContainer.useContainer();

  const [form, setForm] = useState<LoginForm>({
    orderId: '',
    email: '',
  });
  const [errors, setErrors] = useState<Partial<LoginForm>>();
  const [loginError, setLoginError] = useState(false);

  const type = new URLSearchParams(location.search).get('type');

  useEffect(() => {
    if (location?.search) {
      const orderId = new URLSearchParams(location.search).get('order');
      const email = new URLSearchParams(location.search).get('email');
      setForm({ orderId: orderId || '', email: email || '' });
    }
  }, [location]);

  const onOrderSuccess = (data: OrderEditLoadInterface) => {
    setOriginalOrder(data);
    navigate(
      type === '2' ? '/edit-order/edit-time' : `/edit-order/${form.orderId}`,
    );
  };

  const { execute: getOrder } = useGetOrder({
    id: Number(form.orderId),
    onSuccess: onOrderSuccess,
  });

  const onLoginSuccess = () => {
    setOrderQuoteId(generateUuid(true));
    getOrder();
  };

  const { execute } = useLogin({
    form,
    onSuccess: onLoginSuccess,
    onError: () => setLoginError(true),
  });

  const inputColor = theme.palette.primary.contrastText;

  const fields: Field[] = [
    { label: 'Order Number', name: 'orderId', mask: numberMask },
    { label: 'Email', name: 'email' },
  ];

  const validateForm = () => {
    const newErrors: Partial<LoginForm> = {};
    let isValid = true;

    fields.forEach(field => {
      if (!form[field.name]) {
        newErrors[field.name] = `${field.label} is required`;
        isValid = false;
      }
    });

    if (form.email && !emailValidation(form.email)) {
      newErrors.email = 'Invalid email address';
      isValid = false;
    }

    setErrors(newErrors);

    return isValid;
  };

  const handleSubmit = () => {
    if (validateForm()) {
      setLoginError(false);
      execute();
    }
  };

  const onChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setForm({ ...form, [evt.target.name]: evt.target.value });
    if (errors) validateForm();
  };

  return (
    <Box
      sx={{
        maxWidth: 310,
        textAlign: 'center',
        mt: 1,
      }}
    >
      {fields.map(field => (
        <TextField
          key={field.label}
          label={field.label}
          name={field.name}
          variant="outlined"
          fullWidth
          value={form[field.name]}
          onChange={onChange}
          error={!!errors?.[field.name]}
          helperText={errors?.[field.name]}
          InputLabelProps={{ shrink: !!form[field.name] }}
          inputProps={{
            disableUnderline: true,
          }}
          sx={{
            mt: 0.5,
            mb: 2,

            input: { color: inputColor },
            label: { color: inputColor },

            borderBottom: 'none',
            borderBottomColor: inputColor,

            '& label': {
              // Gambiarra to make label visible if Chrome autofill is used
              backgroundColor: '#335991',
              px: '6px',
            },
            '& label.Mui-focused': {
              color: inputColor,
            },
            '& .MuiInput-underline:after': {
              borderBottomColor: inputColor,
            },
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: inputColor,
              },
              '&:hover fieldset': {
                borderColor: inputColor,
              },
              '&.Mui-focused fieldset': {
                borderColor: inputColor,
              },
            },
          }}
        />
      ))}
      {loginError && (
        <Text
          fontSize={16}
          fontWeight={700}
          color="common.white"
          textAlign="center"
        >
          Order number and email is not valid
        </Text>
      )}
      <Button
        variant="contained"
        sx={{ mt: 3, width: isMobile ? 140 : 270, mx: 'auto' }}
        onClick={handleSubmit}
        color="secondary"
      >
        Login
      </Button>
    </Box>
  );
};

export default Form;
