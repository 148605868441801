import { FC } from 'react';
import Box from '@mui/material/Box';
import AccessoriesForm from '../Components/AccessoriesForm';

interface Props {
  isEdit?: boolean;
  onCancel?: () => void;
  onConfirm?: () => void;
}

const AcessoriesMobile: FC<Props> = ({ isEdit, onCancel, onConfirm }) => (
  <Box minHeight="100vh" width="100vw">
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      minHeight={300}
    >
      <AccessoriesForm
        isEdit={isEdit}
        onCancel={onCancel}
        onConfirm={onConfirm}
      />
    </Box>
  </Box>
);

export default AcessoriesMobile;
