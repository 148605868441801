import { FC, ReactNode } from 'react';
import Box, { BoxProps } from '@mui/material/Box';

export interface LeftRightProps {
  left?: ReactNode;
  right?: ReactNode;
  sx?: BoxProps['sx'];
}

const LeftRightInfo: FC<LeftRightProps> = ({ left, right, sx }) => {
  return (
    <Box display="flex" flex={1} width="100%" sx={sx}>
      <Box display="flex" flex={1}>
        {left}
      </Box>
      <Box display="flex" flex={1} justifyContent="flex-end">
        {right}
      </Box>
    </Box>
  );
};

export default LeftRightInfo;
