import { useEffect } from 'react';
import { createContainer, Container } from 'unstated-next';
import {
  ProductInterface,
  ResortInterface,
  OrderQuoteItemInputInterface,
} from 'ks-common';

import { Validations } from './useGlobalFormValidations';
import {
  CreateFormContainer,
  CreateFormContainerReturn,
} from './useCreateForm';
import { EditFormContainer, EditFormContainerReturn } from './useEditForm';
import useIsMounted from 'utils/useIsMounted';
import generateUuid from 'utils/generateUuid';

export type ValueOf<T> = T[keyof T];
export interface VacationHome {
  streetAddress?: string;
  city?: string;
  zip?: string;
  state?: string;
  gateCode?: string;
  addressUnknown?: boolean;
}

export enum LocationType {
  Resort = 'resort',
  VacationHome = 'vacationHome',
  Airport = 'airport',
}

export enum LocationMoment {
  DeliveryLocation = 'deliveryLocation',
  ReturnLocation = 'returnLocation',
}

export interface Form {
  step?: 0 | 1 | 2;
  deliveryDate?: string | null;
  returnDate?: string | null;
  product?: ProductInterface;
  accessories: {
    accessories?: number[];
    insurance?: OrderQuoteItemInputInterface['Insurance'];
    nameTag?: OrderQuoteItemInputInterface['NameTag'];
    commentsAndNotes?: OrderQuoteItemInputInterface['SpecialNotes'];
  };
  deliveryLocation: {
    location?: LocationType;
    resort?: ResortInterface;
    vacationHome?: VacationHome;
    time?: string | null;
  };
  returnLocation: {
    sameAsCheckin?: boolean;
    location?: LocationType | null;
    resort?: ResortInterface | null;
    vacationHome?: VacationHome | null;
    time?: string;
  };
  billing?: {
    rental?: number;
    tax?: number;
  };
  orderProductId?: number;
}

export interface AccessoriesFormErrors {
  accessories: string;
  nameTag: string;
  commentsAndNotes: string;
  deliveryLocation: string;
  returnLocation: string;
  deliveryLocationResort: string;
  returnLocationResort: string;
  deliveryVacationHome: string;
  deliveryVacationHomeFields: Partial<Record<keyof VacationHome, string>>;
  returnVacationHome: string;
  returnVacationHomeFields: Partial<Record<keyof VacationHome, string>>;
  deliveryTime: string;
  returnTime: string;
  deliveryDate: string;
  returnDate: string;
}

export type FormErrors = Record<keyof Form, string>;

export type UpdateForm = (
  value: Partial<Form>,
  index?: number,
  saveToLocalStorage?: boolean,
) => void;

export type SetCurrentProduct = (value: Partial<Form>) => void;

export type CommonReturn = {
  products: Partial<Form>[];
  setProducts: React.Dispatch<Partial<Form>[]>;
  currentProduct: Partial<Form>;
  setCurrentProduct: SetCurrentProduct;
  updateCurrentProduct: UpdateForm;
  currentProductIndex: number;
  setCurrentProductIndex: (value: number) => void;
  clearForm: () => void;
  validations: Validations;
  orderQuoteId: string | undefined;
  setOrderQuoteId: React.Dispatch<string>;
};

export type GlobalFormContainerReturn = {
  isEdit: boolean;
  logOut: () => void;
} & CreateFormContainerReturn &
  EditFormContainerReturn;

const useForm = () => {
  const currentPath: string = window.location.href;
  const isEdit = currentPath.includes('edit-order');
  const isMounted = useIsMounted();

  const createValues = CreateFormContainer.useContainer();
  const editValues = EditFormContainer.useContainer();

  useEffect(() => {
    if (isMounted) {
      createValues.setOrderQuoteId(generateUuid(isEdit));
    }
  }, [isEdit]);

  const logOut = () => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
  };

  return {
    isEdit,
    logOut,
    ...createValues,
    ...editValues,
    ...(isEdit ? editValues : createValues),
  };
};

export const FormContainer: Container<GlobalFormContainerReturn, void> =
  createContainer<GlobalFormContainerReturn, void>(useForm);
