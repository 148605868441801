import { FC, Fragment, useState, useMemo } from 'react';
import { Text } from '@concepta/react-material-ui';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import FormLabel from 'app/components/FormLabel';
import InfoIcon from '@mui/icons-material/Info';
import AlertDialog from 'app/components/AlertDialog';
import { FormContainer } from 'app/hooks/useGlobalForm';
import LeftRightInfo from 'app/components/LeftRightInfo';
import {
  OrderQuoteItemOutputInterface,
  OrderQuoteOutputInterface,
} from 'ks-common';
import priceMask from 'utils/priceMask';

interface DialogInfo {
  title: string;
  text: string;
}

interface LeftRight {
  key: string;
  left: string;
  right: string;
  isSubRow?: boolean;
  info?: () => void;
}

const keyNames: Record<keyof OrderQuoteOutputInterface, string> = {
  OrderQuoteId: '',
  DiscountValue: '',
  PremiumFeeValue: 'Premium Charge',
  TaxValue: 'Sales Tax',
  TotalValue: 'Order Total',
  SubTotalValue: 'Subtotal',
  Items: '',
  TapfilliateId: '',
  ItemsValue: '',
  ValidDiscount: '',
  DiscountQty: '',
  TaxQty: '',
  RentalValue: '',
  CouponCode: '',
  PendingAmountValue: '',
};

interface Props {
  quoteData?: OrderQuoteOutputInterface;
  isPending?: boolean;
  title?: string;
  isThankYouScreen?: boolean;
}

const CostSummary: FC<Props> = ({
  quoteData,
  isPending,
  title,
  isThankYouScreen,
}) => {
  const { isEdit } = FormContainer.useContainer();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogInfo, setDialogInfo] = useState<DialogInfo>({
    title: '',
    text: '',
  });

  const handleCloseDialog = () => setDialogOpen(false);

  const openPremiumDialog = () => {
    setDialogInfo({
      title: 'Premium Charge',
      text: "There's a $10.00 premium for 6AM deliveries",
    });
    setDialogOpen(true);
  };

  const renderRow = ({ key, left, right, isSubRow, info }: LeftRight) => (
    <LeftRightInfo
      key={key}
      sx={{ mt: isSubRow ? 0 : 1 }}
      left={
        <Box display="flex" alignItems="center" onClick={info}>
          <Text
            fontSize={isSubRow ? 12 : 16}
            fontWeight={isSubRow ? 400 : 600}
            ml={isSubRow ? 2 : 0}
          >
            {left}
          </Text>
          {!!info && (
            <InfoIcon
              sx={{
                marginLeft: '6px',
                cursor: 'pointer',
                fontSize: '18px',
                color: 'primary.darker',
              }}
            />
          )}
        </Box>
      }
      right={
        <Text fontSize={isSubRow ? 12 : 16} fontWeight={isSubRow ? 400 : 600}>
          {right}
        </Text>
      }
    />
  );

  const rows: LeftRight[] = useMemo(() => {
    const _rows: LeftRight[] = [];
    if (!quoteData?.Items) return [];

    // Add items first
    quoteData.Items.forEach((item, index) => {
      const itemId = item.Product.ProductID;

      _rows.push({
        key: `${index}-${itemId}`,
        left: item.Product.ProductName,
        right: priceMask(item.StrollerPerDayValue * 100),
      });

      const accessoriesSum = item.Accessories.reduce(
        (total, item) => total + item.Price,
        0,
      );

      if (accessoriesSum > 0) {
        _rows.push({
          key: `${index}-${itemId}-accessories`,
          left: 'Accessories',
          right: priceMask(accessoriesSum * 100),
          isSubRow: true,
        });
      }

      if (item.Insurance) {
        _rows.push({
          key: `${index}-${itemId}-insurance`,
          left: 'Insurance',
          right: priceMask(item.InsuranceValue * 100),
          isSubRow: true,
        });
      }
    });

    // Add other data
    Object.keys(quoteData).forEach(key => {
      const value: string | number | boolean | OrderQuoteItemOutputInterface[] =
        quoteData[key as keyof OrderQuoteOutputInterface];

      if (
        typeof value !== 'number' ||
        [
          'Items',
          'RentalValue',
          'TotalValue',
          'OrderQuoteId',
          'ValidDiscount',
          'CouponCode',
          'SubTotalValue',
          'TaxValue',
          'DiscountValue',
          'PendingAmountValue',
        ].includes(key) ||
        value === 0
      )
        return null;

      const infoDialog: Partial<
        Record<keyof OrderQuoteOutputInterface, () => void>
      > = {
        PremiumFeeValue: openPremiumDialog,
      };

      _rows.push({
        key,
        left: keyNames[key as keyof OrderQuoteOutputInterface] || key,
        right: priceMask(value * 100),
        info: infoDialog[key as keyof OrderQuoteOutputInterface],
      });
    });

    if (quoteData.DiscountValue) {
      _rows.push({
        key: 'DiscountValue',
        left: 'Discount',
        right: `- ${priceMask(quoteData.DiscountValue * 100)}`,
      });
    }

    return _rows;
  }, [quoteData]);

  return (
    <Fragment>
      <Box width="100%">
        <FormLabel mt={4}>{title || 'Order Summary'}</FormLabel>

        {isPending && (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            my={4}
          >
            <CircularProgress />
          </Box>
        )}

        {!isPending && quoteData && (
          <Fragment>
            {rows.map(row => renderRow(row))}

            <Divider sx={{ my: 1 }} />

            {renderRow({
              key: 'SubTotalValue',
              left: keyNames.SubTotalValue,
              right: priceMask(quoteData.SubTotalValue * 100),
            })}

            {renderRow({
              key: 'TaxValue',
              left: keyNames.TaxValue,
              right: priceMask(quoteData.TaxValue * 100),
              isSubRow: true,
            })}

            <Divider sx={{ my: 1 }} />

            {renderRow({
              key: 'TotalValue',
              left: keyNames.TotalValue,
              right: priceMask(quoteData.TotalValue * 100),
            })}

            {isEdit && !isThankYouScreen && (
              <>
                <Divider sx={{ my: 1 }} />
                {renderRow({
                  key: 'AlreadyPaid',
                  left: 'Already Paid',
                  right: priceMask(
                    (quoteData.TotalValue - quoteData.PendingAmountValue) * 100,
                  ),
                })}
                {renderRow({
                  key: 'PendingAmountValue',
                  left:
                    quoteData.PendingAmountValue < 0
                      ? 'Refund Amount'
                      : 'Pending Amount',
                  right: priceMask(
                    Math.abs(quoteData.PendingAmountValue) * 100,
                  ),
                })}
              </>
            )}
          </Fragment>
        )}
      </Box>
      <AlertDialog
        open={dialogOpen}
        title={dialogInfo?.title}
        text={dialogInfo?.text}
        onClose={handleCloseDialog}
      />
    </Fragment>
  );
};

export default CostSummary;
