import { FC } from 'react';
import { Image, Text } from '@concepta/react-material-ui';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Container from '@mui/material/Container';
import facebook from 'assets/icons/facebook.svg';
import instagram from 'assets/icons/instagram.svg';
import youTube from 'assets/icons/youTube.svg';
import { isMobile } from 'utils/isMobile';
import miniLogo from 'assets/images/kgd-strollers-mini-logo.png';

const Footer: FC = () => {
  const socialLinks = [
    {
      key: 'facebook',
      image: facebook,
      alt: 'facebook link',
      url: 'https://www.facebook.com/KingdomStrollers',
    },
    {
      key: 'instagram',
      image: instagram,
      alt: 'instagram link',
      url: 'https://www.instagram.com/kingdomstrollers/',
    },
    {
      key: 'youTube',
      image: youTube,
      alt: 'youTube link',
      url: 'https://www.youtube.com/channel/UCtUilwLeDvjFCN9Wfl3PjpA',
    },
  ];

  return (
    <Box bgcolor="primary.200" paddingY={isMobile ? 4 : 7}>
      <Container maxWidth="xl">
        <Box display="flex" flex={1} flexDirection="column">
          {isMobile && (
            <Box display="flex" justifyContent="center" mb={1}>
              <Image
                src={miniLogo}
                alt="Kingdom Strollers logo"
                width="auto"
                height="100%"
                maxHeight="80px"
              />
            </Box>
          )}
          <Box display="flex" flex={1} px={4}>
            {!isMobile && (
              <Box display="flex" flex={1} justifyContent="center">
                <Text
                  fontFamily="Roboto"
                  fontWeight={400}
                  fontSize={isMobile ? 10 : 16}
                  color={isMobile ? 'grey.400' : 'common.black'}
                >
                  Copyright 2025 Kingdom Strollers
                  <br />
                  All Rights Reserved
                </Text>
              </Box>
            )}
            <Box
              display="flex"
              flex={1}
              justifyContent="center"
              alignItems="center"
            >
              {socialLinks.map(link => (
                <Link href={link.url} marginX={2} key={link.key}>
                  <Image
                    src={link.image}
                    alt={link.alt}
                    maxWidth={isMobile ? 20 : 30}
                    maxHeight={isMobile ? 20 : 30}
                  />
                </Link>
              ))}
            </Box>
            <Box
              display="flex"
              flex={1}
              justifyContent="center"
              alignItems="center"
            >
              <Link
                href={process.env.REACT_APP_KS_TAPFILIATE_URL}
                underline="none"
                fontWeight={400}
                fontSize={isMobile ? 14 : 16}
                sx={{ cursor: 'pointer' }}
              >
                Become an Affiliate
              </Link>
            </Box>
          </Box>

          {isMobile && (
            <Box display="flex" flex={1} justifyContent="center" mt={2}>
              <Text
                fontFamily="Roboto"
                fontWeight={400}
                fontSize={isMobile ? 10 : 16}
                color={isMobile ? 'grey.400' : 'common.black'}
              >
                Copyright 2025 Kingdom Strollers. All Rights Reserved
              </Text>
            </Box>
          )}
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
