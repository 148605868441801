import { FC } from 'react';
import useDataProvider, { useQuery } from '@concepta/react-data-provider';
import { Button, CircularProgress } from '@mui/material';
import { toast } from 'react-toastify';
import { captureException } from '@sentry/react';

import { EditFormContainer } from 'app/hooks/useEditForm';

const ResendConfirmationEmail: FC = () => {
  const { originalOrder } = EditFormContainer.useContainer();

  const { post } = useDataProvider();

  const postEmail = () => {
    return post({
      uri: `/order/order-email-confirmation/${originalOrder?.OrderId}`,
      body: {
        id: originalOrder?.OrderId,
      },
    });
  };

  const { execute, isPending } = useQuery(postEmail, false, {
    onError: errorResponse => {
      // Todo: Get error response from back correctly
      const errorRes = errorResponse as unknown as {
        response: { data: { message: string } };
      };

      toast.error(errorRes?.response?.data?.message || errorResponse?.message);

      captureException({
        category: 'Resend Confirmation Email',
        message: 'Error on resend cofirmation email',
        error: errorResponse,
      });
    },
    onSuccess: () => {
      toast.success('Confirmation email sent');
    },
  });

  return (
    <Button
      variant="contained"
      sx={{ textTransform: 'none', minWidth: '214px' }}
      onClick={execute}
    >
      {isPending ? (
        <CircularProgress size="22px" sx={{ color: 'white' }} />
      ) : (
        'Resend Confirmation Email'
      )}
    </Button>
  );
};

export default ResendConfirmationEmail;
