import { FC, useEffect, useState, useRef } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import {
  FormContainer,
  LocationMoment,
  LocationType,
} from 'app/hooks/useGlobalForm';
import useGetAllResorts from 'app/hooks/useGetAllResorts';
import { ResortInterface } from 'ks-common';
import { isMobile } from 'utils/isMobile';

interface Props {
  id: LocationMoment;
  location: LocationType;
  error?: string;
}

const LocationResort: FC<Props> = ({ id, location, error }) => {
  const { currentProduct, updateCurrentProduct } = FormContainer.useContainer();
  const { data } = useGetAllResorts();

  const locationRef = useRef<HTMLElement>(null);

  const selectedResort = currentProduct[id]?.resort;
  const selectedResortId = currentProduct[id]?.resort?.ResortId;

  const [value, setValue] = useState<ResortInterface | null>(
    selectedResort || null,
  );

  const onChange = (
    event: React.SyntheticEvent,
    newValue: ResortInterface | null,
  ) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (value?.ResortId !== selectedResort?.ResortId) {
      updateCurrentProduct({
        [id]: {
          resort: value,
        },
      });
    }
  }, [value]);

  useEffect(() => {
    if (id === 'returnLocation') {
      if (selectedResort && selectedResort?.ResortId !== value?.ResortId) {
        setValue(selectedResort);
      }
    }
  }, [selectedResortId]);

  const sortDisneyAndName = (a: ResortInterface, b: ResortInterface) => {
    if (a.ResortType === 1 && b.ResortType !== 1) {
      return -1;
    } else if (a.ResortType !== 1 && b.ResortType === 1) {
      return 1;
    } else {
      return a.ResortName.localeCompare(b.ResortName);
    }
  };

  const filteredResorts =
    location === LocationType.Resort
      ? data?.filter(
          resort => resort.ResortType === 1 || resort.ResortType === 0,
        )
      : data;
  const sortedResorts: ResortInterface[] =
    filteredResorts && [...filteredResorts].sort(sortDisneyAndName);

  return (
    <Box
      ref={locationRef}
      sx={{
        scrollMargin: '73px',
      }}
    >
      <Autocomplete
        id={id}
        getOptionLabel={(option: ResortInterface) => option.ResortName}
        options={sortedResorts || []}
        groupBy={option => (option.ResortType === 1 ? 'Disney' : 'Resorts')}
        value={value}
        onChange={onChange}
        disablePortal
        onOpen={() => {
          if (locationRef?.current && isMobile) {
            locationRef.current.scrollIntoView({
              block: 'start',
            });
          }
        }}
        sx={{
          mt: 1,
        }}
        renderInput={params => (
          <TextField
            {...params}
            label="Location"
            variant="standard"
            helperText={error}
            error={!!error}
          />
        )}
        componentsProps={{
          popper: {
            popperOptions: { strategy: 'fixed' },
            style: {
              zIndex: 1500,
              boxShadow: '0px 1px 6px 2px rgba(0, 0, 0, 0.25)',
            },
          },
        }}
        renderGroup={params => (
          <Box key={params.key}>
            <Box
              component="ul"
              sx={{
                fontSize: 18,
                fontWeight: 600,
                pl: 2,
                mt: 2,

                ...(isMobile && {
                  fontSize: 14,
                  mt: 1,
                }),
              }}
            >
              {params.group}
            </Box>
            <Box
              component="ul"
              sx={{
                pl: isMobile ? 1 : 2,
                li: {
                  marginTop: '8px',
                  fontSize: 16,

                  ...(isMobile && {
                    minHeight: '0 !important',
                    marginTop: 0,
                    fontSize: 12,
                  }),
                },
              }}
            >
              {params.children}
            </Box>
          </Box>
        )}
      />
    </Box>
  );
};

export default LocationResort;
