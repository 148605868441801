import { FC } from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { Text } from '@concepta/react-material-ui';
import KgdCard from 'app/components/KgdCard';
import { isMobile } from 'utils/isMobile';
import castleBg from 'assets/images/castle-bg-mobile.jpg';
import castleBgDesktop from 'assets/images/castle-bg-desktop.jpg';

interface Props {
  orderNumber?: number;
  isEdit?: boolean;
}

const OrderNumber: FC<Props> = ({ orderNumber, isEdit }) => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      py={5}
      px={15}
      sx={{
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundImage: `url('${isMobile ? castleBg : castleBgDesktop}')`,
        '@media (max-width: 1100px)': {
          px: 2,
        },
      }}
    >
      <Container maxWidth="xl">
        <KgdCard grow>
          <Box py={2} sx={{ px: 2 }} textAlign="center">
            {!isEdit && (
              <Text
                fontSize={isMobile ? 24 : 36}
                fontWeight={isMobile ? 700 : 500}
                color="primary.contrastText"
                textAlign="center"
                display={isMobile ? 'block' : 'inline'}
              >
                Thank You!
              </Text>
            )}
            <Text
              fontSize={isMobile ? 18 : 36}
              fontWeight={500}
              color="primary.contrastText"
              textAlign="center"
              mb={3}
              display={isMobile ? 'block' : 'inline'}
            >
              {isEdit
                ? 'Your order has been successfully updated!'
                : 'We’ve Received your order!'}
            </Text>

            {!isMobile && <br />}

            <Text
              fontSize={isMobile ? 9 : 36}
              fontWeight={isMobile ? 500 : 700}
              color={isMobile ? 'grey.400' : 'primary.contrastText'}
              textAlign="center"
              display={isMobile ? 'block' : 'inline'}
            >
              ORDER NUMBER{isMobile ? '' : ': '}
            </Text>
            <Text
              fontSize={isMobile ? 19 : 36}
              fontWeight={800}
              color="primary.contrastText"
              textAlign="center"
              display={isMobile ? 'block' : 'inline'}
            >
              #{orderNumber}
            </Text>
          </Box>
        </KgdCard>
      </Container>
    </Box>
  );
};

export default OrderNumber;
