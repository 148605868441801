import { FC, useEffect, useState } from 'react';
import { useNavigate } from '@concepta/react-router';
import { Box } from '@mui/material';
import { Text } from '@concepta/react-material-ui';
import {
  OrderCheckoutOutputInterface,
  OrderEditLoadInterface,
} from 'ks-common';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import useOrderUpdate, { CustomerForm } from 'app/hooks/useOrderUpdate';
import Container from '@mui/material/Container';
import { Button } from '@mui/material';

import { EditFormContainer } from 'app/hooks/useEditForm';
import CostSummary from 'app/components/CostSummary/CostSummary';
import useOrderQuote from 'app/hooks/useOrderQuote';
import { isMobile } from 'utils/isMobile';
import emailValidation from 'utils/emailValidation';
import { productFormaterForComparison } from 'utils/productFormaterForComparison';
import OrderInfoHeader from './OrderInfoHeader';
import Form, { FormErrors } from './Form';
import ResendConfirmationEmail from './ResendConfirmationEmail';
import Review from './Review';
import generateUuid from 'utils/generateUuid';

const EditOrder: FC = () => {
  const {
    products,
    originalProducts,
    originalOrder,
    customerInfo,
    originalCustomerInfo,
    setOriginalOrder,
    setOrderQuoteId,
  } = EditFormContainer.useContainer();
  const navigate = useNavigate();
  const [formErrors, setFormErrors] = useState<FormErrors>();
  const canEdit = originalOrder?.OrderBlock === 0;
  const oder48HoursWarning = originalOrder?.OrderBlock === 1;
  const orderAdminBlock = originalOrder?.OrderBlock === 2;
  const hideCostSummary = orderAdminBlock;
  const errorMessage =
    (oder48HoursWarning &&
      'Your order is less than 48 hours away, please call us if you need any adjustments.') ||
    (orderAdminBlock &&
      'This order cannot be changed here - please contact us by email.') ||
    undefined;

  const productsChanged = !isEqual(
    productFormaterForComparison(products),
    productFormaterForComparison(originalProducts),
  );
  const customerInfoChanged = !isEqual(customerInfo, originalCustomerInfo);

  const orderChanged = productsChanged || customerInfoChanged;

  const {
    data: quoteData,
    execute,
    isPending,
    error,
  } = useOrderQuote(products, originalOrder?.OrderId);
  useEffect(() => {
    if (products?.length > 0) execute();
  }, [products]);

  const { execute: executeUpdate, isPending: updateIsPending } = useOrderUpdate(
    {
      orderId: originalOrder?.OrderId,
      products,
      customer: customerInfo as CustomerForm,
      orderQuoteId: quoteData?.OrderQuoteId,
      onSuccess: (order: OrderCheckoutOutputInterface) => {
        setOrderQuoteId(generateUuid(true));
        if (originalOrder && order) {
          const newOrderData: OrderEditLoadInterface = cloneDeep(originalOrder);
          newOrderData.OrderQuoteOutput = order.OrderQuoteCalculated;
          newOrderData.Customer = {
            ...newOrderData.Customer,
            ...order.Customer,
          };

          setOriginalOrder(newOrderData);
        }
      },
    },
  );

  const validateForm = () => {
    const newErrors: FormErrors = {};
    let isValid = true;

    if (!customerInfo?.primaryCellPhone) {
      newErrors.primaryCellPhone = 'Primary Cell Phone is required';
      isValid = false;
    }

    if (
      customerInfo?.emailAddress &&
      !emailValidation(customerInfo?.emailAddress)
    ) {
      newErrors.emailAddress = 'Invalid email address';
      isValid = false;
    }

    if (
      customerInfo?.secondaryEmail &&
      !emailValidation(customerInfo?.secondaryEmail)
    ) {
      newErrors.secondaryEmail = 'Invalid email address';
      isValid = false;
    }

    setFormErrors(newErrors);

    return isValid;
  };

  useEffect(() => {
    if (formErrors && Object.keys(formErrors).length) validateForm();
  }, [customerInfo]);

  const valueDifference = quoteData?.PendingAmountValue;
  const isSameValue = quoteData && valueDifference === 0;
  const isRefundValue = valueDifference && valueDifference < 0;
  const isDueValue = valueDifference && valueDifference > 0;

  const handleSubmit = () => {
    if (!validateForm()) return;

    if (isDueValue) {
      return navigate('/edit-order/checkout');
    }

    executeUpdate();
  };

  const submitButton = () => {
    if (isSameValue || isRefundValue) {
      return (
        <Button
          variant="contained"
          fullWidth
          onClick={handleSubmit}
          disabled={updateIsPending || !orderChanged}
        >
          {isSameValue ? 'UPDATE ORDER' : 'REFUND AND UPDATE'}
        </Button>
      );
    }

    return (
      <Button
        variant="contained"
        fullWidth
        onClick={handleSubmit}
        disabled={!quoteData || !!error || !orderChanged}
      >
        PAY AND UPDATE
      </Button>
    );
  };

  const actionButtons = (
    <>
      <Box
        sx={{
          width: '100%',
          mt: isMobile ? 3 : 5,
          ...(!isMobile && { display: 'flex', flexDirection: 'row-reverse' }),
        }}
      >
        {submitButton()}
        <Button
          variant="contained"
          onClick={() =>
            originalOrder && setOriginalOrder(cloneDeep(originalOrder))
          }
          fullWidth
          sx={{
            color: 'grey.600',
            backgroundColor: 'grey.300',
            '&:hover': {
              backgroundColor: 'grey.400',
            },
            ...(isMobile && { my: 1 }),
            ...(!isMobile && { mr: 2 }),
          }}
        >
          CANCEL
        </Button>
      </Box>
      {formErrors && Object.keys(formErrors).length > 0 && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            mt: 2,
          }}
        >
          {Object.values(formErrors).map(error => (
            <Text
              color="error.main"
              fontWeight={600}
              fontSize={14}
              key={error}
              mt={1}
            >
              {error}
            </Text>
          ))}
        </Box>
      )}
    </>
  );

  return (
    <Container maxWidth="xl">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: isMobile ? 'center' : 'flex-start',
          flex: 1,
          width: '100%',
        }}
      >
        <OrderInfoHeader />

        {isMobile && errorMessage && (
          <Text
            color="error.main"
            fontWeight={600}
            fontSize={14}
            my={3}
            align="center"
          >
            {errorMessage}
          </Text>
        )}

        {isMobile && <ResendConfirmationEmail />}

        <Form errors={formErrors} readonly={!canEdit} />

        <Review
          quoteData={quoteData}
          isPending={isPending}
          readonly={!canEdit}
          hideCostSummary={hideCostSummary}
          errorMessage={errorMessage}
        >
          {canEdit && actionButtons}
        </Review>

        {isMobile && !hideCostSummary && (
          <>
            <CostSummary
              quoteData={quoteData}
              isPending={isPending}
              title="New Order Summary"
            />

            {canEdit && actionButtons}
          </>
        )}
      </Box>
    </Container>
  );
};

export default EditOrder;
