import { FC, Fragment, useState } from 'react';
import { FormContainer, Form } from 'app/hooks/useGlobalForm';
import ReactGA from 'react-ga4';
import AlertDialog, { AlertType } from 'app/components/AlertDialog';
import ExpandableStrollerCard, {
  ExpandableStrollerCardType,
} from 'app/components/ExpandableStrollerCard';
import ProductDisplay from './ProductDisplay';
import { gaProductFormatter } from 'utils/GAnalytics/formatters';
import formatDate from 'utils/formatDate';

export interface FormErrors {
  nameTag: string;
}
interface Props {
  index: number;
  product: Partial<Form>;
  readonly?: boolean;
  isEdit?: boolean;
}

const ProductCard: FC<Props> = ({ index, product, readonly, isEdit }) => {
  const { removeProduct } = FormContainer.useContainer();
  const stroller = product.product;

  const [removeProductDialogOpen, setRemoveProductDialogOpen] = useState(false);

  const handleRemoveClick = () => {
    setRemoveProductDialogOpen(true);
  };

  const handleRemoveConfirm = () => {
    const gaData = gaProductFormatter(product);
    ReactGA.event('remove_from_cart', gaData);
    removeProduct(index);
  };

  const handleClose = () => {
    setRemoveProductDialogOpen(false);
  };

  const reviewDates =
    product?.deliveryDate &&
    product?.returnDate &&
    `${formatDate(product.deliveryDate, 'MM/DD/YY')} - ${formatDate(
      product.returnDate,
      'MM/DD/YY',
    )}`;

  return (
    <Fragment>
      {stroller && (
        <ExpandableStrollerCard
          stroller={stroller}
          type={ExpandableStrollerCardType.REVIEW}
          handleActionClick={handleRemoveClick}
          reviewDates={reviewDates}
          readonly={readonly}
          isEdit={isEdit}
        >
          <ProductDisplay
            product={product}
            index={index}
            readonly={readonly}
            isEdit={isEdit}
          />
        </ExpandableStrollerCard>
      )}

      <AlertDialog
        alertType={AlertType.CONFIRMATION}
        open={removeProductDialogOpen}
        title="Attention"
        text="Are you sure you want to remove this item from your cart?"
        onClose={handleClose}
        onConfirm={handleRemoveConfirm}
      />
    </Fragment>
  );
};

export default ProductCard;
